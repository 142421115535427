import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { ListDataSource } from "./ListDataSource";

export class ContentBlockFileDataSource extends ListDataSource {
  public contentBlockId;

  constructor(contentBlockId: number, config: IBaseConfig = {}) {
    super({
      className: "ContentBlockFile",
      config,
    });

    this.contentBlockId = contentBlockId;
  }

  get fileUrl(): string {
    return `/manage/ContentBlockFile/file`;
  }

  async add(event: any) {
    try {
      const { data } = await axios.post(
        `${this.fileUrl}/${this.contentBlockId}`,
        event.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress(e) {
            event.onProgress(e, event.file);
          },
        },
      );
      this.items.push(data);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async update(image: any) {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `${this.fileUrl}${image.id}`,
        image.base64,
        config,
      );
      return data;
    } catch (err) {
      throw err;
    }
  }
}
