import { getGUID } from "@/cms-services/helpers";
import { validate } from "@/cms-services/validators";
import Vue from "vue";

const editor = Vue.extend({
  props: {
    field: Object,
    editor: Object,
    actions: Array,
    customValidators: Object,
  },
  data: () => ({
    attrs: {},
    validations: [],
    watchers: null as any,
    config: {},
    inputId: "" as string,
  }),
  computed: {
    showErrors(): Boolean {
      return this.editor?.errorMessages?.length > 0;
    },
    hint(): string {
      return this.editor?.attrs?.hint;
    },
  },
  watch: {
    model: {
      deep: true,
      async handler() {
        this.onModelChanged();
      },
    },
  },
  created() {
    this.inputId = getGUID();
    if (!this.editor) throw new Error("Настройки поля не переданы");
    this.$set(this.editor, "errorMessages", []);
    this.$set(this.editor, "successMessages", []);
    this.attrs = this.editor?.attrs;
    this.validations = this.editor?.validations;
    this.watchers = this.editor?.watchers;
    this.config = this.editor?.config;
    this.onModelChanged();
  },
  methods: {
    async validate(): Promise<boolean> {
      if (!this.editor.validations) return true;

      const _res: any = await validate(
        { name: "value", validations: this.validations },
        this.field,
        this.customValidators,
      );
      this.editor.errorMessages = _res.errorMessages;
      this.editor.successMessages = _res.successMessages;
      return this.editor.errorMessages.length === 0;
    },
    async onModelChanged() {
      if (!(this.watchers?.length > 0)) return;
      for (const watcher of this.watchers) {
        const { changeModel, changeAttrs } = watcher;
        if (!!changeModel) await (this as any).actions[changeModel]();
        if (!!changeAttrs)
          this.attrs = await (this as any).actions[changeAttrs](this.attrs);
      }
    },
  },
});

export default editor;
