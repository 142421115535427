
import Vue from "vue";
import ContentEditorEditable from "@/bootstrap/components/editor/editable.vue";
import FieldConfigurator from "@/bootstrap/components/form/configurator.vue";
import ContentFormApp from "@/bootstrap/components/form/app.vue";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { getGUID } from "@/cms-services/helpers";
import {
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BButton,
  BIconGear,
  BIconPencil,
  BIconFiles,
  BIconTrash,
  BIconArrowsMove,
  BIconEye,
  BIconEyeSlash,
  BIconPlus,
} from "bootstrap-vue";
import Ace from "@/bootstrap/components/editor/ace.vue";
import StringEditor from "./editors/string.vue";
import NumberEditor from "./editors/string.vue";
import BooleanEditor from "./editors/bool.vue";
import EnumEditor from "./editors/enum.vue";
import components from "./components.json";
import draggable from "vuedraggable";
import _ from "lodash";
import VueContext from "vue-context";
export default Vue.extend({
  name: "content-form-constructor",
  components: {
    ContentFormApp,
    FieldConfigurator,
    ContentEditorEditable,
    draggable,
    StringEditor,
    NumberEditor,
    BooleanEditor,
    EnumEditor,
    BTabs,
    BTab,
    Ace,
    BDropdown,
    BDropdownItem,
    BButton,
    BIconGear,
    BIconPencil,
    BIconFiles,
    BIconTrash,
    BIconArrowsMove,
    BIconEye,
    BIconEyeSlash,
    BIconPlus,
    VueContext,
  },
  props: {
    dataSource: ObjectDataSource,
  },
  data: () => ({
    json: "",
    sellectedSection: null as any,
    loading: false as any,
    filteredFields: [] as any,
    components,
    configurateEntity: "",
    showNavigation: true,
    toEditSection: null as any,
    toEditButton: null as any,
    contextMenuVisible: false,
    x: 0,
    y: 0,
  }),
  computed: {
    configurate(): any {
      return (this as any).$refs.configurator.configurate;
    },
  },

  async created() {
    await this.checkIsNull();
    const sections = this.dataSource?.model?.sections;
    if (!this.sellectedSection && sections?.length > 0) {
      this.sellectedSection = sections[0];
    }
    this.updateFilteredFields();
    this.json = JSON.stringify(this.dataSource?.model?.model, null, 2);
    this.loading = true;
  },

  methods: {
    selectToEditSection(e, section) {
      this.configurateEntity = "section";
      this.toEditSection = section;
      e.preventDefault();
      this.contextMenuVisible = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.contextMenuVisible = true;
      });
    },
    selectToEditButton(e, button) {
      this.configurateEntity = "button";
      this.toEditButton = button;
      e.preventDefault();
      this.contextMenuVisible = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.contextMenuVisible = true;
      });
    },
    hasCorrectName(field) {
      return typeof this.dataSource?.model?.model[field?.name] !== "undefined";
    },
    isSelected(section) {
      return section?.id === this.sellectedSection?.id;
    },
    getGrid: (field: any) => field?.grid ?? { class: "col-12" },
    selectSection(section: any) {
      if (!section) throw new Error("Нельзя передать null");
      this.sellectedSection = section;
      this.updateFilteredFields();
    },
    updateFilteredFields() {
      if (!this.sellectedSection) return;
      const fields = this.dataSource.model?.fields;
      if (!(fields?.length > 0)) {
        this.filteredFields = [];
        return;
      }
      this.filteredFields = fields
        .filter((f) => f.sectionId === this.sellectedSection.id)
        .sort((p: any, n: any) => p.priority - n.priority);
    },
    async configurateSection() {
      if (!this.toEditSection) throw new Error("Нельзя передать null");
      try {
        const _section = await this.configurate(this.toEditSection);
        if (!_section) return;
        const _sections = this.dataSource.model?.sections;
        const index = _sections.findIndex((b) => b.id === _section.id);
        this.$set(_sections, index, _section);
        await this.updateSections();
      } catch {}
    },
    async configurateButton() {
      if (!this.toEditButton) throw new Error("Нельзя передать null");
      if (!this.sellectedSection) return;

      try {
        const _button = await this.configurate(this.toEditButton);
        if (!_button) return;
        const cb = (b) => b.id === _button.id;
        const index = this.sellectedSection.buttons.findIndex(cb);
        this.$set(this.sellectedSection.buttons, index, _button);
        await this.updateSections();
      } catch {}
    },
    async deleteButton() {
      if (!this.toEditButton) throw new Error("Нельзя передовать null");
      const _buttons = this.sellectedSection?.buttons;
      if (!(_buttons?.length > 0)) return;
      const msg = "Вы действительно хотите удалить данную кнопку??";
      const _confirm = await (this as any).$getConfirm(msg);
      if (!_confirm) return;
      const cb = (b) => b.id === this.toEditButton?.id;
      const _index = _buttons.findIndex(cb);
      if (_index === -1) throw new Error("Элемент не найден");
      _buttons.splice(_index, 1);
      await this.updateSections();
      this.updateFilteredFields();
    },
    async configurateField(field) {
      if (!field) throw new Error("Нельзя передать null");
      try {
        const _field = await this.configurate(field);
        if (!_field) return;
        const cb = (f) => f.id === _field.id;
        const index = this.dataSource.model.fields.findIndex(cb);
        this.$set(this.dataSource.model.fields, index, _field);
        this.updateFilteredFields();
        await this.updateFields();
      } catch {}
    },
    async checkIsNull() {
      if (!Array.isArray(this.dataSource?.model?.sections)) {
        await this.dataSource.updateField({
          fieldName: "sections",
          fieldValue: [],
        });
      }

      if (!Array.isArray(this.dataSource?.model?.fields)) {
        await this.dataSource.updateField({
          fieldName: "fields",
          fieldValue: [],
        });
      }

      if (!this.dataSource?.model?.model) {
        await this.dataSource.updateField({
          fieldName: "model",
          fieldValue: {},
        });
      }
    },
    async addField(field: any) {
      if (!this.sellectedSection) {
        return (this as any).$alert("Пожалуйста выберите секцию");
      }
      const fields = this.dataSource.model.fields;
      fields.push(
        _.cloneDeep({
          ...field,
          id: getGUID(),
          priority: 0,
          sectionId: this.sellectedSection.id,
        }),
      );

      await this.dataSource.updateField({
        fieldName: "fields",
        fieldValue: fields,
      });
      this.updateFilteredFields();
    },
    async updateModel() {
      try {
        await this.dataSource.updateField({
          fieldName: "model",
          fieldValue: JSON.parse(this.json),
        });
      } catch {}
    },
    async changePriorities() {
      const fields = this.dataSource?.model?.fields;
      if (!(fields?.length > 0)) return;
      if (!(this.filteredFields?.length > 0)) return;
      this.filteredFields.forEach((f, i) => (f.priority = i));
      fields.forEach((field) => {
        this.filteredFields.forEach((filteredField) => {
          if (field.id === filteredField.id) {
            Object.assign(field, filteredField);
          }
        });
      });

      await this.updateFields();
    },
    async removeField(id) {
      const fields = this.dataSource?.model?.fields;
      if (!(fields?.length > 0)) return;
      const index = fields.findIndex((f) => f.id === id);
      if (index === -1) throw new Error("элемент не найден");
      this.dataSource?.model?.fields.splice(index, 1);
      await this.updateFields();
      this.updateFilteredFields();
    },
    async addBtn() {
      this.sellectedSection.buttons.push({
        id: getGUID(),
        class: "btn btn-primary",
        caption: "Кнопка",
        action: "",
      });
      await this.updateSections();
    },
    async addSection() {
      this.dataSource.model.sections.push({
        id: getGUID(),
        caption: "Секция",
        isActive: false,
        class: "",
        buttons: [],
      });
      await this.updateSections();
    },
    async deleteSection() {
      if (!this.toEditSection) throw new Error("Нельзя передовать null");
      const _sections = this.dataSource?.model?.sections;
      if (!(_sections?.length > 0)) return;
      const _confirm = await (this as any).$getConfirm(
        "Вы действительно хотите удалить данную секцию со всеми его элементами??",
      );
      if (!_confirm) return;
      const _index = _sections.findIndex(
        (s) => s.id === this.toEditSection?.id,
      );
      if (_index === -1) throw new Error("Элемент не найден");
      if (this.dataSource?.model?.fields?.length > 0) {
        const cb = (f) => f.sectionId !== this.toEditSection?.id;

        this.dataSource.model.fields = this.dataSource.model.fields.filter(cb);
      }
      _sections.splice(_index, 1);
      this.sellectedSection = _sections[0];
      await this.updateSections();
      await this.updateFields();
      this.updateFilteredFields();
    },
    async updateFields() {
      await this.dataSource.updateField({
        fieldName: "fields",
        fieldValue: this.dataSource.model.fields,
      });
    },
    async updateSections() {
      await this.dataSource.updateField({
        fieldName: "sections",
        fieldValue: this.dataSource.model.sections,
      });
    },
  },
});
