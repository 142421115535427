
import { Vue, Component } from "vue-property-decorator";

@Component
export default class DataList extends Vue {
  navigation: any = {
    shown: true,
    width: 400,
    minSize: 100,
  };

  async created() {
    await this.$nextTick();
    const minSize = this.navigation.minSize;
    const leftPanel = this.$refs.leftPanel as HTMLElement;
    const border = this.$refs.border as HTMLElement;
    const resize = (e) => {
      document.body.style.cursor = "ew-resize";
      let leftPos = leftPanel?.parentElement?.getBoundingClientRect()?.left;
      let f = e.clientX - (leftPos ?? 0);
      leftPanel.style.flexBasis = (f > minSize ? f : minSize) + "px";
    };

    border?.addEventListener(
      "mousedown",
      (e: any) => {
        if (e.offsetX < minSize) {
          leftPanel.style.transition = "initial";
          document.addEventListener("mousemove", resize, false);
        }
      },
      false,
    );

    document.addEventListener(
      "mouseup",
      () => {
        leftPanel.style.transition = "";
        this.navigation.width = leftPanel.style.flexBasis;
        document.body.style.cursor = "";
        document.removeEventListener("mousemove", resize, false);
      },
      false,
    );
  }
}
