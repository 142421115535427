
import Editor from "./editor";
import { Component, Vue } from "vue-property-decorator";
@Component({
  mixins: [Editor],
})
export default class StringEditor extends Vue {
  attrs: any;
  get mask() {
    return this.attrs?.mask;
  }
}
