
import { ContentBlock } from "@/bootstrap/components/content/block";
import FileMixin from "@/mixins/file-types";

export default ContentBlock.extend({
  name: "content-block-img-01",
  mixins: [FileMixin],
  computed: {
    breakpoint() {
      return (this as any).block?.config?.breakpoint;
    },
    height() {
      return (this as any).block?.config?.height;
    },
    sizes(){
      const width =  (this as any).block?.config?.width;
      const height =  (this as any).block?.config?.height;
      let size = "";
      if(width) size += `width:${width};`
      if(height) size += `height:${height};`
      return size;
    }
  },
  methods: {
    async select(selectDocument:(files:any[])=>Promise<any[]>) {
      this.block.model = await selectDocument(this.block.model);
      await this.updateModel();
      (this as any).$message("Изображение успещно обновлено");
    },
    getFileComponent(file: any){
      switch (file.fileName.substring(file.fileName.lastIndexOf(".") + 1)) {
        case "jpg":
        case "JPG":
        case "JPEG":
        case "jpeg":
        case "png":
        case "PNG":
          return "image-icon";
        case "pdf":
          return "pdf";
        case "docx":
        case "doc":
          return "docx";
        case "xlsx":
          return "xlsx";
        case "txt":
          return "txt";
        case "pptx":
        case "ppt":
          return "pptx";
        case "mp3":
          return "mp3";
        case "zip":
        case "rar":
          return "zip";
        default:
          return "file";
      }
    },

    isImage(fileName: string){
      const ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      return ["jpg", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(ext);
    }
  },
});
