
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import {
  findElementInTree,
  generateBreadCrumbsFromTree,
  getFilePreview,
} from "@/cms-services/helpers";
import {
  mdiClose,
  mdiFilePdfBox,
  mdiFolder,
  mdiFolderOpen,
  mdiMenu,
  mdiPlusCircleOutline,
} from "@mdi/js";
import { ListDataSource } from "@/data/List/ListDataSource";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
import FilePreviews from "@/mixins/file-types/index";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import { Filter, FilterUtils } from "@/cms-services/filter";
import FileSystemLayout from "@/components/helpers/file-system-layout.vue";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import FolderRestrictor from "@/components/helpers/folder-restrictor.vue";
import FolderScenarioDialog from "@/components/helpers/folder-scenario-dialog.vue";
import _ from "lodash";
import moment from "moment";
import userModule from "@/store/modules/user";
@Component({
  mixins: [FilePreviews],
  components: {
    ObjectDataTable,
    FileSystemLayout,
    DataEditorDialog,
    FolderRestrictor,
    FolderScenarioDialog,
  },
})
export default class University extends Vue {
  @Ref("folderEditor") folderEditor!: DataEditorDialog;
  @Ref("folderScenario") folderScenario!: FolderScenarioDialog;
  @Ref("modelCreator") modelCreator!: DataEditorDialog;
  @Ref("restrictor") restrictor!: FolderRestrictor;
  @Prop({ default: null }) customStateColor!: Function;
  @Prop() breadcrumbs!: any;
  @Prop() dataSource!: ListDataSource;
  @Prop() folderDataSource!: FolderDataSource;
  @Prop() customHeaders!: any[];
  @Prop({ default: true }) activatable!: boolean;
  @Prop({ default: () => ({}) }) createItemActions: any;
  @Prop({ default: () => ({}) }) createFolderActions: any;
  @Prop({ default: null }) customCreateItemFields!: any;
  @Prop({ default: null }) customCreateItemModel!: any;
  @Prop({ default: null }) customMetaData!: any;
  @Prop() title!: string;

  filterUtils: FilterUtils = new FilterUtils([]);
  headers: any = [];
  loaded = false;
  searchText: string = "";
  timer: any = null;
  folderBreadCrumbs: any = [];
  activeFolder: any = null;
  activeFolderAsArray: any = [];
  createItemFields: any = [];
  createItemModel: any = {};
  moment = moment;
  icons: any = {
    menu: mdiMenu,
    close: mdiClose,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    pdf: mdiFilePdfBox,
    circlePlus: mdiPlusCircleOutline,
  };
  folderContextMenu: any = {
    visible: false,
    x: null,
    y: null,
    data: null,
  };

  createFolderFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
        class: "mt-5",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "systemname",
      attrs: {
        label: "Системное имя",
        type: "text",
      },
      name: "name",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
      validations: [],
    },
  ];

  $message: any;
  folderAccessVisible: boolean = false;

  get isAdmin() {
    return userModule.roles.includes("Administrators");
  }

  get folders() {
    return this.activeFolder?.children;
  }

  get hasFolders() {
    return this.folders?.length > 0;
  }

  async add() {
    if (!this.activeFolder?.id) {
      return this.$message("Пожалуйста выберите категорию", "error");
    }
    const model: any = await this.modelCreator.create(this.createItemModel);
    if (!model) return;
    model.folderId = this.activeFolder?.id;
    try {
      await this.dataSource.add(model);
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async editScenrios() {
    await this.folderScenario.edit(this.folderContextMenu.data.id);
  }

  async restrictFolder() {
    await this.restrictor.restrict(this.folderContextMenu.data);
  }

  getFilePreview = getFilePreview;

  getFolderUrl(id: any) {
    return `/${this.$route.path}/?folderId=${id}`;
  }

  getActiveFolder(folderId?: number, folders?: any[]) {
    const _folderId = folderId ?? +this.$route.query?.folderId;
    const _folders = folders ?? this.folderDataSource.items;
    return findElementInTree(_folderId, _folders) ?? _folders[0];
  }

  startTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      const folder = !!this.searchText ? this.folderDataSource.items[0] : null;
      await this.changeFolder([folder]);
    }, 1000);
  }
  getCreateItemFields = (fields: any[], custom: any[]) => {
    if (custom) return [...custom];

    if (!(fields?.length > 0)) {
      throw new Error("метаданные не определены");
    }
    const hasRequired = (v: any) =>
      v.filter((i: any) => i.validator === "required")?.length > 0;

    return fields
      .filter((f: any) => hasRequired(f?.validations))
      .map((f: any) => this.getEditorConfig(f));
  };

  getCreateItemModel = (fields: any, custom: any) => {
    if (custom) return { ...custom };
    if (!(fields?.length > 0)) {
      throw new Error("метаданные не определены");
    }
    const object = {};
    for (const field of fields) object[field.name] = null;

    return object;
  };

  getEditorConfig(field: any) {
    const { name, caption, editor, validations, config } = field;
    let attrs = {};

    switch (editor) {
      default: {
        attrs = {
          type: "text",
          label: caption,
        };
        break;
      }
    }

    return {
      editor,
      name,
      config,
      attrs,
      validations,
    };
  }

  async created() {
    await this.folderDataSource.get();

    if (!this.customMetaData) {
      await this.dataSource.getMetaData();
    } else {
      this.dataSource.metadata = this.customMetaData;
    }

    await this.changeFolder([this.getActiveFolder()]);
    this.createItemFields = this.getCreateItemFields(
      this.dataSource?.metadata?.properties,
      this.customCreateItemFields
    );

    this.createItemModel = this.getCreateItemModel(
      this.createItemFields,
      this.customCreateItemModel
    );

    this.loaded = true;
  }

  openFolderAccessDialog() {
    this.folderAccessVisible = true;
    this.folderContextMenu.visible = false;
  }

  async openFolderContextMenu(data, e) {
    this.folderContextMenu.visible = false;
    this.folderContextMenu.x = e.clientX;
    this.folderContextMenu.y = e.clientY;
    await this.$nextTick();
    this.folderContextMenu.data = data;
    this.folderContextMenu.visible = true;
  }

  async addFolder() {
    const _data: any = await this.folderEditor.create({
      parentId: null,
      caption: null,
      description: null,
      name: null,
    });
    if (!_data) return;
    try {
      _data.parentId = this.folderContextMenu?.data?.id;
      await this.folderDataSource.add(_data);
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async updateFolder() {
    const _data: any = await this.folderEditor.update(
      this.folderContextMenu.data
    );
    if (!_data) return;
    try {
      await this.folderDataSource.update(_data);
      this.$message("Успешно Обновлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async removeFolder() {
    if (this.folderContextMenu.data?.children?.length > 0) {
      return this.$message(
        "Нельзя удалять элементы у которых есть дочерние элементы",
        "error"
      );
    }

    try {
      await this.folderDataSource.remove(this.folderContextMenu.data.id);
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }

  async addRootFolder() {
    await this.folderDataSource.add({
      parentId: null,
      caption: "Все",
      name: "mainFolder",
    });
  }

  async routeToPage(item: any) {
    await this.changeFolder([this.getActiveFolder(item.id)]);
  }

  public async changeFolder(activeFolderAsArray: any = null) {
    const activeFolder = activeFolderAsArray?.[0] ?? this.getActiveFolder();
    this.activeFolder = activeFolder;
    if (!this.activeFolder?.id) return;
    this.activeFolderAsArray = [activeFolder];
    this.filterUtils.clear();
    if (!!this.searchText) {
      this.filterUtils.add(new Filter("searchText", this.searchText));
    }
    if (!!this.activeFolder?.id) {
      this.filterUtils.add(new Filter("folderId", this.activeFolder?.id));
    }
    this.dataSource.config.filter = this.filterUtils.filterString;
    await this.dataSource.get();
    this.folderBreadCrumbs = generateBreadCrumbsFromTree(
      this.activeFolder,
      this.folderDataSource.items,
      (x: any) => ({
        text: x.caption,
        href: this.getFolderUrl(x.id),
        id: x.id,
      })
    );
  }
}
