
import Editor from "./editor";
import { Component, Vue } from "vue-property-decorator";
import { mdiEyeOffOutline, mdiEyeOutline, mdiRefreshAuto } from "@mdi/js";
import { generatePassword } from "@/cms-services/helpers";
@Component({
  mixins: [Editor],
})
export default class PasswordEditor extends Vue {
  type: string = "password";
  value: any;
  icons: any = {
    eye: mdiEyeOutline,
    eyeOff: mdiEyeOffOutline,
    generate: mdiRefreshAuto,
  };

  public get isPassword(): boolean {
    return this.type === "password";
  }

  generatePassword() {
    this.value = generatePassword();
  }
}
