
import Vue from "vue";
import { BSidebar } from "bootstrap-vue";
import { mapMutations } from "vuex";
import ContentBlockCanvas from "@/bootstrap/components/content/block/canvas.vue";
import ContentBlockPallete from "@/bootstrap/components/content/block/pallete.vue";
import ContentBlockConfigurator from "@/bootstrap/components/content/block/configurator.vue";
import ContentBlockModelEditor from "@/bootstrap/components/content/block/model-editor.vue";
import { contentBlockModule } from "@/bootstrap/store/contentBlock";

export default Vue.extend({
  name: "content-block-constructor",
  components: {
    BSidebar,
    ContentBlockCanvas,
    ContentBlockPallete,
    ContentBlockConfigurator,
    ContentBlockModelEditor,
  },
  props: {
    contentId: {
      type: Number,
      required: true,
    },
    componentDataSource:{
      type:Object,
      required:true
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },

  async created() {
    if (!this.contentId) throw new Error("contentId не передан");
    this.$store.commit(
      contentBlockModule.mutations.setContentId,
      this.contentId,
    );
    this.$store.commit(
      contentBlockModule.mutations.setComponentDataSource,
      this.componentDataSource,
    );

  },

  methods: {
    ...mapMutations({
      open: contentBlockModule.mutations.openBlockConfigurator,
    }),
  },
});
