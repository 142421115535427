
import { Vue, Component, Ref } from "vue-property-decorator";
import editor from "./editor";
import { TreeDataSource } from "@/data/Tree/TreeDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import {
  getGUID,
  getLastLevelBranches,
  getParentBrachesOfTreeByKeyList,
} from "@/cms-services/helpers";
import _ from "lodash";
import {ListDataSource} from "@/data/List/ListDataSource";

@Component({
  mixins: [editor],
  components: { DataEditorDialog },
})
export default class ResourceInlineEditor extends Vue {
  @Ref("creator") creator!: DataEditorDialog;
  dialog = false;
  notifications = false;
  getGuid = getGUID;
  sound = true;
  widgets = false;
  field: any;
  loaded: boolean = false;
  contextMenu: any = {
    visible: false,
    x: null,
    y: null,
    data: null,
  };
  model: any = {
    parentId: null,
    caption: null,
    description: null,
    name: null,
  };

  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "systemname",
      attrs: {
        label: "Системное имя",
        type: "text",
      },
      name: "name",
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
      validations: [],
    },
  ];
  $message: any;
  $confirm: any;
  dataSource: any;

  get hasItems() {
    return this.resourceFolderDataSource?.items?.length > 0;
  }

  get folders() {
    if(this.isSingleConfig){
      return this.dataSource.model[this.field.name].map((i: any) => i.id);
    }
    const tree = this.resourceFolderDataSource.items;
    if (!(tree?.length > 0)) return [];
    const lastLevelBranches = getLastLevelBranches(tree);
    const selectedBranches = this.dataSource.model[this.field.name];
    const filteredBranches = _.intersectionBy(
      lastLevelBranches,
      selectedBranches,
      "id"
    );

    return filteredBranches.map((i: any) => i.id);
  }

  get allfolders() {
    return this.dataSource.model[this.field.name].map((i: any) => ({
      id: i.id,
      caption: i.caption,
    }));
  }

  get isSingleConfig(){
    return this.resourceFolderDataSource.classConfig.configType == "Single"
  }


  resourceFolderDataSource: TreeDataSource = new TreeDataSource({
    className: "",
  });

  findUniqueElements(A: number[], B: number[]): [number[], number[]] {
    const forRemove = A.filter(element => !B.includes(element));
    const forAdd = B.filter(element => !A.includes(element));
    return [forAdd, forRemove];
  }

  async change(checkedItems:any) {
    try {
      if(this.resourceFolderDataSource.classConfig?.configType == "Single"){
        if(checkedItems.length == 0) return;
        await this.resourceFolderDataSource.updateFolders(checkedItems, this.dataSource?.model.id);
        await this.dataSource.get();
        this.$message("Успешно добавлено");
        return;
      }
      const _items = getParentBrachesOfTreeByKeyList(
        checkedItems,
        this.resourceFolderDataSource.items
      );
      await this.resourceFolderDataSource.updateFolders(
        _items.map((i: any) => i.id),
        this.dataSource?.model.id
      );
      this.dataSource.model[this.field.name] = _items;
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async created() {
    this.resourceFolderDataSource.className = this.field?.config?.className;
    await this.resourceFolderDataSource.get("mainFolder");
    await this.resourceFolderDataSource.getConfig();
    this.loaded = true;
  }

  async openContextMenu(data = null, e) {
    this.contextMenu.visible = false;
    this.contextMenu.x = e.clientX;
    this.contextMenu.y = e.clientY;
    await this.$nextTick();
    this.contextMenu.data = data;
    this.contextMenu.visible = true;
  }

  async add() {
    const _data: any = await this.creator.create(this.model);
    if (!_data) return;
    try {
      _data.parentId = this.contextMenu?.data?.id;
      await this.resourceFolderDataSource.add(_data);
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async update() {
    const _data: any = await this.creator.update(this.contextMenu.data);
    if (!_data) return;
    try {
      await this.resourceFolderDataSource.update(_data);
      this.$message("Успешно Обновлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async remove() {
    if (this.contextMenu.data?.children?.length > 0) {
      return this.$message(
        "Нельзя удалять элементы у которых есть дочерние элементы",
        "error"
      );
    }

    try {
      await this.resourceFolderDataSource.remove(this.contextMenu.data.id);
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async addRootElement() {
    await this.resourceFolderDataSource.add({
      parentId: null,
      caption: "Все",
      name: "mainFolder",
    });
  }
}
