
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline, mdiThumbDown, mdiThumbUp,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import _ from "lodash";
import { remove } from "@/cms-services/helpers";
import { Filter } from "@/cms-services/filter";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  dialog: boolean = false;
  likeDataSource:ListDataSource = new ListDataSource({
    className:"componentRating",
    config:{
      filter:JSON.stringify([new Filter("componentId", this.dataSource.id)])
    }
  })
  loaded: boolean = false;
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    like:mdiThumbUp,
    dislike:mdiThumbDown
  };

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Автор",
      value: "userProfile",
      sortable: true,
    },
    {
      text: "Значение",
      value: "value",
      sortable: true,
    },
  ];


  async created(){
    await this.likeDataSource.get();
    this.loaded = true;
  }


}
