
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class InlineCountrySelector extends Vue {
  loaded: boolean = false;
  countryDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 1000,
      filter: JSON.stringify([new Filter("regionTypeId", 1)]),
    },
  });
  value: any;

  get valueCaption() {
    const cb = (c: any) => +c.id === +this.value;
    const country = this.countryDataSource.items.find(cb);
    return country?.name;
  }

  async created() {
    await this.countryDataSource.get();
    this.loaded = true;
  }
}
