
import Vue from "vue";
export default Vue.extend({
  name: "editor-inline-editable",
  props: {
    value: {
      type: String,
    },
    canEdit: {
      type: Boolean,
    },
    tag: {
      type: String,
    },
  },
  data: () => ({
    canUpdate: true,
    tempValue: "",
  }),
  watch: {
    value(val: any) {
      if (this.canUpdate) {
        (this as any).$refs.editable.innerHTML = val;
      }
    },
  },
  mounted() {
    (this as any).$refs.editable.innerHTML = this.value;
    this.tempValue = this.value;
  },
  methods: {
    onInput(e: any) {
      this.$emit("input", e.target.innerHTML);
    },

    onFocus() {
      this.canUpdate = false;
    },

    onBlur(e: any) {
      this.canUpdate = true;
      if (this.tempValue == e.target.innerHTML) return;
      this.$emit("change", e.target.innerHTML);
    },
  },
});
