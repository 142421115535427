
import moment from "moment";
import Editor from "./editor";
import { Component, Vue } from "vue-property-decorator";
@Component({
  mixins: [Editor],
})
export default class DateEditor extends Vue {
  menuVisible: boolean = false;
  value: any;
  emit: any;
  config: any;
  get min() {
    return this.config?.min ?? "1950-01-01";
  }

  get max() {
    return this.config?.max ?? new Date().toISOString().substr(0, 10);
  }

  get formatValue() {
    return (this as any).value
      ? moment((this as any).value.substr(0, 10)).format("DD.MM.YYYY")
      : "";
  }
  get calendarValue() {
    return (this as any).value
      ? moment((this as any).value.substr(0, 10)).format("YYYY-MM-DD")
      : null;
  }

  update(val: any) {
    this.value = val;
    this.menuVisible = false;
    this.emit();
  }
}
