
import _ from 'lodash';
import {Vue, Component} from 'vue-property-decorator';

@Component({})
export default class DocumentSelector extends Vue {
  dataSource = this.$store.state["content-block"].componentDataSource;
  visible:boolean = false;
  resolve!:Function
  items:any = []
  isExist(file:any){
    return this.items.some((f:any) => f.id == file.id);
  }

  public open(existFiles:any[]){
    this.visible = true;
    if(Array.isArray(existFiles)){
      this.items = _.cloneDeep([...existFiles]);
    }
    return new Promise(r => this.resolve = r);
  }

  updateItems(event:boolean, file:any){
    if(!event) return this.items = this.items.filter((f:any) => f.id != file.id);
    this.items.push(file);
  }

  public close(model:any){
    this.resolve(model);
    this.visible = false;
  }

  public submit(){
    const clone = _.cloneDeep([...this.items]);
    this.close(clone);
    this.items = [];
  }
}
