
import { Vue, Component, Watch } from "vue-property-decorator";
import editor from "./editor";
import { getInstaceStateName } from "@/cms-services/helpers";
@Component({
  mixins: [editor],
})
export default class StringInlineEditor extends Vue {
  visualizationAdditional: any;
  getName = getInstaceStateName;
  value: any;
  save: any;
  field: any;
  visualization: any;
  get current() {
    return this.field?.items?.find?.((x: any) => x.id === this.value);
  }

  public get activeState() {
    return this.field?.activeState;
  }

  public get disactiveState() {
    return this.field?.disactiveState;
  }

  public get isBtnGroup(): boolean {
    return (!this.field?.mode && !this.field?.config?.mode) || this.field?.mode === "btn-group";
  }

  public get isSwitch(): boolean {
    return this.field?.mode === "switch";
  }

  public get isMenu(): boolean {
    return this.field?.mode === "menu" ||  this.field?.config?.mode === "menu";
  }

  public get btnVisualization() {
    return this.visualization?.btn ?? { xSmall: true };
  }

  public get chipIconVisualization() {
    return (
      this.visualizationAdditional?.chipIcon ?? {
        right: true,
        dark: true,
        small: true,
        icon: "mdi-pen",
      }
    );
  }

  public get chipVisualization() {
    return (
      this.visualizationAdditional?.chip ?? {
        label: true,
        small: true,
        color: "orange",
        dark: true,
      }
    );
  }

  async saveByMenu(item) {
    this.value = item.id;
    await this.save();
  }

  getLabel() {
    return +this.value === +this.activeState.id
      ? this.activeState.caption
      : this.disactiveState.caption;
  }

  async changeValueBySwitch(value) {
    this.value = value ? this.activeState?.id : this.disactiveState?.id;
    await this.save();
  }

  async saveValue(value: any) {
    this.value = value;
    await this.save();
  }
}
