import { IFolderDataSourceConfig } from "@/ioc/types";
import { requiredArgument } from "@/cms-services/validators";
import {
  findElementInTree,
  removeElementInTree,
  updateElementInTree,
} from "@/cms-services/helpers";
import Vue from "vue";
import axios from "axios";
import { checkToken } from "@/cms-services/token";

export class FolderDataSource {
  public metadata!: any;
  public items: any = [];
  public rootFolder: string;
  public className: string;

  public get hasItems(): boolean {
    return this.items.length > 0;
  }

  get baseUrl() {
    return `/manage/${this.className}`;
  }

  constructor({ className, rootFolder, items }: IFolderDataSourceConfig) {
    checkToken();
    this.items = items ?? [];
    this.className = className;
    this.rootFolder = rootFolder;
  }

  async get() {
    try {
      const { data } = await axios.get(
        `${this.baseUrl}/${this.rootFolder}/tree`,
      );
      if (!data) return;
      Vue.set(this.items, 0, data);
    } catch (err) {
      throw err;
    }
  }
  async addObject(folderId: number, objectId: number) {
    if (!(this.items?.length > 0)) throw new Error("items не определены");
    const branch = findElementInTree(folderId, this.items);
    if (!branch) throw new Error("Элемент не найден");
    try {
      await axios.post(`${this.baseUrl}/${folderId}/object/${objectId}`);
    } catch (error) {
      throw error;
    }
  }

  async remove(id: number = requiredArgument()) {
    if (!(this.items?.length > 0)) throw new Error("items не определены");
    const branch = findElementInTree(id, this.items);
    if (!branch) throw new Error("Элемент не найден");
    try {
      await axios.delete(`${this.baseUrl}/${id}/`);
      removeElementInTree(id, this.items);
    } catch (error) {
      throw error;
    }
  }

  async add(model: any = requiredArgument()) {
    const branch = findElementInTree(model.parentId, this.items);
    if (this.items?.length > 0) {
      if (!model?.parentId) throw new Error("parentId не может быть null");
      if (!branch) throw new Error("передан не правельный parentId");
    }

    try {
      const { data } = await axios.post(`${this.baseUrl}/`, model);
      this.items?.length > 0
        ? branch.children.push(data)
        : (this.items = [data]);
    } catch (error) {
      throw error;
    }
  }

  async update(model: any = requiredArgument(), update?: any) {
    const branch = findElementInTree(model.id, this.items);
    if (!branch) throw new Error("Элемент не найден");

    const updateCallBack = update
      ? update
      : (defined: any) => {
          defined.caption = model.caption;
          defined.name = model.name;
          defined.description = model.description;
        };

    try {
      const { data } = await axios.put(`${this.baseUrl}/${model.id}`, model);
      updateElementInTree(data, this.items, updateCallBack);
    } catch (error) {
      throw error;
    }
  }
}
