
import { Vue, Component } from "vue-property-decorator";
import ManageLayout from "./layouts/Manage.vue";
import LoginLayout from "./layouts/Login.vue";
import message from "./components/plugins/message.vue";
import confirm from "./components/plugins/confirm.vue";
@Component({
  components: {
    ManageLayout,
    LoginLayout,
    message,
    confirm,
  },
})
export default class App extends Vue {
  $route: any;
  error: any;
  get layout() {
    let defaultLayout = this.error ? "error" : "manage";
    return `${this.$route?.meta?.layout || defaultLayout}-layout`;
  }

  // @Meta
  // getMetaInfo() {
  //   const meta = { title: "", titleTemplate: "" };
  //   switch (this?.layout) {
  //     case "user-layout":
  //       meta.title = "Личный кабинет";
  //       meta.titleTemplate = "%s | Личный кабинет";
  //       break;
  //     case "auth-layout":
  //       meta.titleTemplate = "%s | Войти";
  //       break;
  //     case "error-layout":
  //       meta.titleTemplate = "Произошла ошибка";
  //       break;
  //     default:
  //       meta.title = "Система управления";
  //       meta.titleTemplate = "%s | Система управления";
  //   }
  //   return meta;
  // }
}
