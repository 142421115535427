
import Vue from "vue";
import Editor from "vue2-ace-editor";
import beautify from "js-beautify";

export default Vue.extend({
  name: "editor-ace",
  components: { Editor },
  props: {
    value: String,
    lang: String,
  },
  mounted() {},
  methods: {
    prettifyXml(sourceXml) {
      var xmlDoc = new DOMParser().parseFromString(
        sourceXml,
        "application/xml",
      );
      var xsltDoc = new DOMParser().parseFromString(
        [
          // describes how we want to modify the XML - indent everything
          '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
          '  <xsl:strip-space elements="*"/>',
          '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
          '    <xsl:value-of select="normalize-space(.)"/>',
          "  </xsl:template>",
          '  <xsl:template match="node()|@*">',
          '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
          "  </xsl:template>",
          '  <xsl:output indent="yes"/>',
          "</xsl:stylesheet>",
        ].join("\n"),
        "application/xml",
      );

      var xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(xsltDoc);
      var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
      if (resultDoc.getElementsByTagName("parsererror").length > 0) {
        throw new Error("Error parsing XML");
        return sourceXml;
      } else {
        var resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
      }
    },

    editorInit(editor) {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/javascript");
      require("brace/mode/sass");
      require("brace/mode/scss");
      require("brace/mode/css");
      require("brace/mode/json");
      require("brace/mode/xml");
      require("brace/mode/razor");
      require("brace/theme/chrome");
      require("brace/snippets/javascript");

      editor.commands.addCommand({
        name: "Format",
        exec: function () {
          let canFormat = true;
          let selection = editor.getSelectedText();
          selection = selection == "" ? null : selection;
          var beautify_js = beautify; // also available under "js" export
          var beautify_css = beautify.css;
          var beautify_html = beautify.html;
          let val = selection != null ? selection : editor.session.getValue();
          switch (editor.getSession().getMode().$id) {
            case "ace/mode/xml":
              {
                val = this.prettifyXml(val);
              }
              break;
            case "ace/mode/javascript":
              {
                val = beautify_js(val);
              }
              break;
            case "ace/mode/json":
              {
                val = beautify_js(val);
              }
              break;
            case "ace/mode/html":
            case "ace/mode/razor":
              {
                val = beautify_html(val, { wrap_attributes: "force-aligned" });
              }
              break;
            case "ace/mode/less":
            case "ace/mode/sass":
            case "ace/mode/scss":
            case "ace/mode/css":
              {
                val = beautify_css(val);
              }
              break;
            default:
              canFormat = false;
              break;
          }
          if (canFormat) {
            if (selection != null) {
              editor.session.replace(editor.selection.getRange(), val);
            } else {
              editor.session.setValue(val);
            }
          }
        },
        bindKey: { mac: "Alt-Shift-F", win: "Alt-Shift-F" },
      });
    },
  },
});
