
import Editor from "./editor";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

import FilePreviews from "@/mixins/file-types/index";
import { getGUID } from "@/cms-services/helpers";
import { mdiCheckAll, mdiClose } from "@mdi/js";

@Component({
  mixins:[Editor, FilePreviews]
})
export default class FileUploader extends Vue {
  @Ref("fileRef") readonly fileRef!: HTMLInputElement;
  @Prop({ default: () => [] }) fileTypes!: string[];
  @Prop({ default: 0 }) maxFiles!: number;
  @Prop({ default: "Перетащите сюда изображения или " }) placeholder!: string;
  @Prop({ default: "выберите..." }) placeholderBtnText!: string;

  dragEnter = false;
  toRenderFiles: any = [];
  loadedResolve!: Function;
  loadedRreject!: Function;
  loadedFiles:any  = [];
  $message: any;
  icons:any = {
    close:mdiClose,
    check:mdiCheckAll
  }

  change({ target }: any) {
    this.readFiles(target);
  }

  onDrop({ dataTransfer }: any) {
    this.dragEnter = false;
    this.readFiles(dataTransfer);
  }

  remove(fileId){
    const self:any = this; 
    const index = self.value.findIndex((f:any) => f.fileId === fileId);
    if(index === -1) return;
    self.value.splice(index,1);
    this.toRenderFiles.splice(index,1);
  }

  async readFiles({ files }: any) {
    const _result = this.validateFiles(files);
    if (_result !== "") {
      this.$message(_result, "error");
      return;
    }
    
    for (const file of files) {
      const _reader = new FileReader();
      _reader.readAsDataURL(file);
      _reader.onload = ({ target }: any) => {
        const _base64 = _reader.result?.toString();
        const _id = getGUID();
        const self:any = this;
        self.value.push({
          fileId:_id,
          ...self.actions[self.config.initFile](_base64,file)
        });
        this.toRenderFiles.push({
          fileId:_id,
          name: file.name,
          size: file.size,
          preview: this.getFilePreview(file, target?.result),
          loadingState: 0
        });
      };
    }
  }
  validateFiles(files: any) {
    if (!files || files.length === 0) return "Загрузите файлы";
    const fileCount = this.toRenderFiles.length + files.length > this.maxFiles;

    if (this.maxFiles !== 0 && fileCount) {
      this.fileRef.value = "";
      return `Вы не можете загрузить больше чем ${this.maxFiles} файлов`;
    }

    if (!(this.fileTypes?.length > 0)) {
      return "";
    }

    for (const file of files) {
      const _isCorrect = this.validateFileType(file);
      if (!_isCorrect) return "Загрузите правельный тип файла";
    }

    return "";
  }

  validateFileType({ name }: any) {
    let _isCorrect = false;
    for (const fileType of this.fileTypes) {
      const _fileExtention = name
        .substring(name.lastIndexOf(".") + 1)
        .toLowerCase();

      if (_fileExtention === fileType.toLowerCase()) {
        _isCorrect = true;
      }
    }

    return _isCorrect;
  }

  getFilePreview = (file: File, preview: any) => {
    if (file.type.split("/")[0] === "image")
      return {
        isImg: true,
        img: preview
      };

    let img;
    switch (file.name.substring(file.name.lastIndexOf(".") + 1)) {
      case "pdf":
        img = "pdf";
        break;
      case "docx":
      case "doc":
        img = "docx";
        break;
      case "xlsx":
        img = "xlsx";
        break;
      case "txt":
        img = "txt";
        break;
      case "pptx":
      case "ppt":
        img = "pptx";
        break;
      case "mp3":
        img = "mp3";
        break;
      case "zip":
      case "rar":
        img = "zip";
        break;
      default:
        img = "file";
        break;
    }

    return {
      isImg: false,
      img
    };
  };
}
