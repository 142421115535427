export const regexList: any = {
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  personName: /^\s+$/,
  minSec: /\d+:[0-5]\d/,
};

export const instaceStates = {
  1: "Создан",
  2: "На подтверждение",
  3: "На активацию",
  4: "Активен",
  5: "Архив",
  6: "Заблокирован",
  7: "Временный",
  8: "Ошибка",
  9: "Отклонен",
  10: "Удален",
  11: "Принят",
  100: "Оплачен",
  200: "Отправлен",
};

/*
1: "Создан",
  "Отправлен"
  "Принят"

*/

export const searchByField =
  (value: any, name: string = "id") =>
  (obj: any) =>
    obj[name] === value;

export const required = (msg: any = "Поле не может быть пустым") => ({
  validator: "required",
  value: true,
  errorMessage: msg,
});

export const emailAddres = (msg: any = "Введите корректный email") => ({
  validator: "regular",
  value: "email",
  errorMessage: msg,
});

export const isCorrectecTime = (
  msg: any = "Введите корректный минуты и секунды",
) => ({
  validator: "regular",
  value: "minSec",
  errorMessage: msg,
});

export const min = (min: any, msg?: any) => ({
  validator: "min",
  value: min,
  errorMessage: msg ?? `Поле не может сожержать меньше чем ${min} символов`,
});

export const sortByPriority = (p: any, n: any) => n.priority - p.priority;
export const sortByPriorityDesc = (p: any, n: any) => p.priority - n.priority;
