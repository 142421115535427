export const initPageRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/pages",
          name: "page",
          component: () => import("@/pages/manage/pages/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/pages/:id",
          name: "page-id",
          component: () => import("@/pages/manage/pages/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
