
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import editor from "./editor";
import FilePreviews from "@/mixins/file-types/index";
import draggable from "vuedraggable";
import { ListDataSource } from "@/data/List/ListDataSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import {
  mdiMenu,
  mdiClose,
  mdiFolderOpen,
  mdiFolder,
  mdiFilePdfBox,
  mdiPlusCircleOutline,
} from "@mdi/js";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { TreeDataSource } from "@/data/Tree/TreeDataSource";
import { ICascadeUpdateData, IUpdateField } from "@/ioc/types";
import { required } from "@/cms-services/consts";
import FolderRestrictor from "@/components/helpers/folder-restrictor.vue";

interface ICascadeUpdateFieldsData {
  className: string;
  fields: ICascadeUpdateData[];
}

@Component({
  mixins: [editor, FilePreviews],
  components: {
    draggable,
    DataEditorDialog,
    FileUploaderDialog,
    FolderRestrictor,
  },
})
export default class ResourceInlineEditor extends Vue {
  @Ref("fileRef") readonly fileRef!: HTMLInputElement;
  @Ref("folderEditor") folderEditor!: DataEditorDialog;
  @Ref("fileEditor") fileEditor!: DataEditorDialog;
  @Ref("restrictor") restrictor!: FolderRestrictor;
  @Prop({ default: 0 }) maxFiles!: number;
  @Prop({ default: () => ({}) }) createFolderActions: any;
  @Prop({ default: "Перетащите сюда изображения или " }) placeholder!: string;
  @Prop({ default: "выберите..." }) placeholderBtnText!: string;

  dialog = false;
  dragEnter = false;
  toRenderFiles: any = [];
  loadedResolve!: Function;
  loadedRreject!: Function;
  $message: any;
  dataSource: any;
  value: any;
  filed: any;
  field: any;
  icons: any = {
    menu: mdiMenu,
    close: mdiClose,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    pdf: mdiFilePdfBox,
    circlePlus: mdiPlusCircleOutline,
  };

  folderContextMenu: any = {
    visible: false,
    x: null,
    y: null,
    data: null,
  };
  uploaderDialogVisible: boolean = false;

  get isFolder() {
    return this.folderContextMenu.data?.classId == 551;
  }

  async restrictFolder() {
    await this.restrictor.restrict(this.folderContextMenu.data);
  }

  get itemCaption() {
    const { data } = this.folderContextMenu;
    return data?.classId == 551 ? data?.caption : data.fileName;
  }

  get noFolderFiles() {
    return this.value.filter((f: any) => !f.folderId);
  }

  async saveChanges() {
    const folders: ICascadeUpdateFieldsData = {
      className: "AttachedFileFolder",
      fields: [],
    };
    const files: ICascadeUpdateFieldsData = {
      className: "LinkedComponentFile",
      fields: [],
    };
    for (const child of this.folderDataSource.items) {
      this.saveChange(child, folders, files);
    }
    await this.cascadeUpdateFields(folders, files);
  }

  async saveChange(
    element: any,
    folders: ICascadeUpdateFieldsData,
    files: ICascadeUpdateFieldsData
  ) {
    if (element.classId !== 551) return;

    for (const [index, child] of element.children.entries()) {
      const name = { 551: "parentId", 503: "folderId" }[child.classId];
      const updateFields: IUpdateField[] = [
        { fieldName: "priority", fieldValue: index },
      ];
      if (+child[name[child.classId]] != +element.id) {
        updateFields.push({ fieldName: name, fieldValue: element.id });
      }
      switch (child.classId) {
        case 551:
          folders.fields.push({
            id: child.id,
            fields: updateFields,
          });
          break;

        case 503:
          files.fields.push({
            id: child.id,
            fields: updateFields,
          });
          break;
      }

      for (const field of updateFields) {
        child[field.fieldName] = field.fieldValue;
      }

      this.saveChange(child, folders, files);
    }
  }

  async cascadeUpdateFields(
    folders: ICascadeUpdateFieldsData,
    files: ICascadeUpdateFieldsData
  ) {
    await Promise.all([
      this.folderDataSource.updateFieldCascade(folders.fields),
      this.linkedFilesDataSource.updateFieldCascade(files.fields),
    ]);
  }

  createFolderFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
        class: "mt-5",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "systemname",
      attrs: {
        label: "Системное имя",
        type: "text",
      },
      name: "name",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
      validations: [],
    },
  ];

  fileFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
        class: "mt-5",
      },
      name: "fileName",
      validations: [required()],
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
    },
  ];

  async uploadFile(event: any) {
    await this.dataSource.uploadFiles(
      event.data,
      this.filed?.name ?? "linkedFiles",
      this.folderContextMenu.data.id,
      event.opProgress,
      event.file
    );
    this.fillFiles();
  }

  async openFolderContextMenu(data, e) {
    this.folderContextMenu.visible = false;
    this.folderContextMenu.x = e.clientX;
    this.folderContextMenu.y = e.clientY;
    await this.$nextTick();
    this.folderContextMenu.data = data;
    this.folderContextMenu.visible = true;
  }

  async addFolder() {
    const _data: any = await this.folderEditor.create({
      parentId: null,
      caption: null,
      description: null,
      componentId: this.dataSource.id,
      name: null,
    });
    if (!_data) return;
    try {
      _data.parentId = this.folderContextMenu?.data?.id;
      await this.folderDataSource.add(_data);
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async updateFolder() {
    if (this.folderContextMenu.data.classId == 551) {
      const data: any = await this.folderEditor.update(
        this.folderContextMenu.data
      );
      if (!data) return;
      try {
        await this.folderDataSource.update(data);
        this.$message("Успешно Обновлено");
      } catch (error) {
        this.$message(error, "error");
      }
      return;
    }

    const data: any = await this.fileEditor.update(this.folderContextMenu.data);
    if (!data) return;
    try {
      this.folderDataSource.className = this.folderContextMenu.data.className;
      await this.folderDataSource.update(data, (defined: any) => {
        defined.fileName = data.fileName;
        defined.description = data.description;
      });
      this.folderDataSource.className = "AttachedFileFolder";
      this.$message("Успешно Обновлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async removeFolder() {
    if (this.folderContextMenu.data?.children?.length > 0) {
      return this.$message(
        "Нельзя удалять элементы у которых есть дочерние элементы",
        "error"
      );
    }

    try {
      this.folderDataSource.className = this.folderContextMenu.data.className;
      await this.folderDataSource.remove(this.folderContextMenu.data.id);
      this.folderDataSource.className = "AttachedFileFolder";
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }

  linkedFilesDataSource: ListDataSource = new ListDataSource({
    className: "LinkedComponentFile",
    config: {},
  });

  folderDataSource: TreeDataSource = new TreeDataSource({
    className: "AttachedFileFolder",
  });

  async changePriority() {
    const priorities: any = [];
    for (const [index, file] of this.value.entries()) {
      file.priority = index;
      priorities.push({
        id: file.id,
        priority: index,
      });
    }
    this.value = this.value.sort((p: any, n: any) => p.priority - n.priority);
    await this.linkedFilesDataSource.changePriority(priorities);
  }

  created() {
    this.folderDataSource.items = this.dataSource.model.fileFolders;
    this.fillFiles();
  }

  fillFiles() {
    for (const folder of this.folderDataSource.items) {
      this.putFiles(folder);
    }
  }

  putFiles(folder: any) {
    for (const file of this.value) {
      if (file.folderId !== folder.id) continue;
      if (folder.children.some((c: any) => c.classId == 503 && c.id == file.id))
        continue;
      folder.children.push(file);
      folder.children = folder.children.sort(
        (p: any, n: any) => p.priority - n.priority
      );
    }

    for (const children of folder.children) {
      if (children.classId !== 551) continue;
      this.putFiles(children);
    }
  }

  getDownloadUrl(file:any) {
    return `/api/v1/manage/attachedfile/${file.id}/file?download=true`;
  }

  async addRootFolder() {
    await this.folderDataSource.add({
      caption: "Все",
      name: "all",
      componentId: this.dataSource.id,
      parentId: null,
    });
  }

  async deleteFile(file: any) {
    await this.dataSource.deleteFile(file.id, this.field.name);
    this.$message(`${file.fileName} успешно удалён`);
  }

  getFilePreview = (fileName) => {
    switch (fileName.substring(fileName.lastIndexOf(".") + 1)) {
      case "pdf":
      case "PDF":
        return "pdf";
      case "docx":
      case "doc":
        return "docx";
      case "xlsx":
      case "xls":
        return "xlsx";
      case "txt":
        return "txt";
      case "pptx":
      case "ppt":
        return "pptx";
      case "mp3":
        return "mp3";
      case "zip":
      case "rar":
        return "zip";
      default:
        return "file";
    }
  };
}
