export const initBaseRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/",
          name: "home",
          component: () => import("@/pages/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              "Administrators",
              "Site Editors",
              "Invitation Supervisor",
              "Event Managers",
              "Support Manager",
              "WebOrder Managers",
            ],
          },
        },
        {
          path: "/login/auth/",
          name: "login-auth",
          component: () => import("@/pages/login/auth.vue"),
          meta: {
            layout: "login",
          },
        },
      ];
