
import { ListDataSource } from "@/data/List/ListDataSource";
import { ComponentLinkDataSource } from "@/data/List/ComponentLinkDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  searchTimer: any = null;
  dialog: boolean = false;
  searchText: string = "";
  filterText: string = "";
  loaded: boolean = false;
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  professionalAreaDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 100 },
    className: "professionalArea",
  });

  linkedProfessionalAreaDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 100 },
    className: "professionalArea",
  });

  componentLinkDataSource: ComponentLinkDataSource =
    new ComponentLinkDataSource();
  $message: any;

  updateProfessionalAreas() {
    let sourceItems = [...this.professionalAreaDataSource.items];
    console.log(this.dataSource.model);

    for (const commodity of sourceItems) {
      const cb = (c: any) => c.id == commodity.id;

      const exists = this.dataSource.model?.linkedProfessionalArea.some(cb);
      if (exists) {
        const index = this.professionalAreaDataSource.items.findIndex(cb);
        this.professionalAreaDataSource.items.splice(index, 1);
      }
    }
    this.linkedProfessionalAreaDataSource.items = this.dataSource.model?.linkedProfessionalArea;
  }

  filterLinkedProfessionalArea() {
    this.updateProfessionalAreas();
    if (!this.filterText) return;
    const re = new RegExp(this.filterText, "gi");
    this.linkedProfessionalAreaDataSource.items = this.linkedProfessionalAreaDataSource.items.filter(
      (c: any) => c.caption.match(re),
    );
  }

  async created() {
    await this.professionalAreaDataSource.get();
    this.linkedProfessionalAreaDataSource.metadata = this.professionalAreaDataSource.metadata;
    this.linkedProfessionalAreaDataSource.total =
      this.dataSource.model?.linkedProfessionalArea?.length;
    this.updateProfessionalAreas();
    this.loaded = true;
  }

  async add(item: any) {
    await this.componentLinkDataSource.add({
      componentId: item.id,
      componentClassId: item.classId,
      linkedComponentId: this.dataSource.id,
      linkedComponentClassId: this.dataSource.model?.classId,
    });

    await this.dataSource.get();
    this.updateProfessionalAreas();
    this.linkedProfessionalAreaDataSource.total = this.linkedProfessionalAreaDataSource.items.length;
    this.$message("Успешно добавлено");
  }

  async remove(item: any) {
    await this.componentLinkDataSource.remove({
      componentId: item.id,
      componentClassId: item.classId,
      linkedComponentId: this.dataSource.id,
      linkedComponentClassId: this.dataSource.model?.classId,
    });
    const index = this.linkedProfessionalAreaDataSource.items.findIndex(
      (c: any) => c.id === item.id,
    );
    if (index === -1) throw new Error("не найден");
    this.linkedProfessionalAreaDataSource.items.splice(index, 1);
    this.professionalAreaDataSource.items.push(item);
    this.linkedProfessionalAreaDataSource.total = this.linkedProfessionalAreaDataSource.items.length;

    this.$message("Успешно удалено");
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.professionalAreaDataSource.config.filter = JSON.stringify([
        {
          fieldName: "searchText",
          fieldValue: value,
        },
      ]);
      await this.professionalAreaDataSource.get();
      this.updateProfessionalAreas();
    }, 1000);
  }
}
