
import Editor from "./editor";
import { Component, Vue } from "vue-property-decorator";
import { VCheckbox, VSwitch } from "vuetify/lib";
@Component({
  mixins: [Editor],
  components: { VCheckbox, VSwitch },
})
export default class BoolEditor extends Vue {
  field: any;
  public get type(): string {
    return this.field?.config?.type ?? "switch";
  }
}
