
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
import moment from "moment/moment";
@Component({
  mixins: [editor],
})
export default class StringInlineEditor extends Vue {
  value: any;
  save: any;
  cancelChanges: any;

  public get isValidValue(){
    return moment(this.value, this.dateFormat,true).isValid()
  }

  public get placeholder(){
    return (this as any).field?.config?.dateFormat ?? "DD.MM.YYYY HH:mm"
  }

  public get dateFormat(){
    return (this as any).field?.config?.dateFormat ?? "DD.MM.YYYY HH:mm"
  }

  public get maskValue(){
    return (this as any).field?.config?.mask ?? (this as any).field?.mask
  }

  async saveDate(){
    if(!this.isValidValue){
      this.cancelChanges();
      return;
    }
   await this.save();
  }

  isValidDateFormat(dateString: string, format: string): boolean {
    // Создаем объект Date из переданной строки
    const date = new Date(dateString);

    // Проверяем, является ли полученный объект Date валидной датой
    if (isNaN(date.getTime())) {
      return false; // Если дата невалидная, возвращаем false
    }

    // Функция для проверки даты в соответствии с форматом
    const checkFormat = (date: Date, format: string): boolean => {
      const formattedDate = {
        dd: String(date.getDate()).padStart(2, '0'),
        MM: String(date.getMonth() + 1).padStart(2, '0'),
        yyyy: String(date.getFullYear()),
        HH: String(date.getHours()).padStart(2, '0'),
        mm: String(date.getMinutes()).padStart(2, '0'),
        ss: String(date.getSeconds()).padStart(2, '0')
      };

      // Заменяем форматные символы на соответствующие значения из даты
      const formattedString = format.replace(/dd|MM|yyyy|HH|mm|ss/g, (match) => {
        return formattedDate[match];
      });

      // Проверяем, соответствует ли полученная строка формату
      return formattedString === dateString;
    };

    return checkFormat(date, format);
  }

}
