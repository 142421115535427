import { requiredArgument } from "../../cms-services/validators";
import {
  findElementInTree,
  removeElementInTree,
  updateElementInTree,
} from "@/cms-services/helpers";
import Vue from "vue";
import {
  ICascadeUpdateData,
  ITReeDataSourceConfig,
  IUpdateField,
} from "@/ioc/types";
import axios from "axios";
import { checkToken } from "@/cms-services/token";

export class TreeDataSource {
  public metadata!: any;
  public items: any = [];
  public className: string;
  public classConfig:any;

  get baseUrl() {
    return `/manage/${this.className}`;
  }

  constructor({ className, items }: ITReeDataSourceConfig) {
    checkToken();
    this.items = items ?? [];
    this.className = className;
  }

  async get(folderName: string) {
    try {
      const { data } = await axios.get(`${this.baseUrl}/${folderName}/tree`);
      if (!data) return;
      Vue.set(this.items, 0, data);
    } catch (err) {
      throw err;
    }
  }

  async move(id:number, parentId:number){
    try {
      await axios.put(`${this.baseUrl}/move/${id}/to/${parentId}`);
    }catch (e) {
      throw e;
    }
  }

  async updatePriority(tree:any[]){
    try {
      await axios.patch(`${this.baseUrl}/priority/tree`,tree);
    }catch (e) {
      throw e;
    }
  }

  async remove(id: number) {
    if (!(this.items?.length > 0)) throw new Error("items не определены");
    const branch = findElementInTree(id, this.items);
    if (!branch) throw new Error("Элемент не найден");
    try {
      await axios.delete(`${this.baseUrl}/${id}/`);
      removeElementInTree(id, this.items);
    } catch (error) {
      throw error;
    }
  }

  async getConfig(){
    try {
      const {data}= await axios.get(`${this.baseUrl}/config/`);
      this.classConfig = data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateField(item: any, updateData: IUpdateField[]) {
    try {
      const { data } = await axios.patch(
        `${this.baseUrl}/${item.id}`,
        updateData,
      );
      updateData.forEach((ud: any) =>
        Vue.set(item, ud.fieldName, ud.fieldValue),
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateFieldCascade(fields: ICascadeUpdateData[]) {
    try {
      const { data } = await axios.patch(`${this.baseUrl}/cascade`, fields);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async add(model: any) {
    if (!model) throw new Error("Нельзя передать null");
    const branch = findElementInTree(model.parentId, this.items);

    if (this.items?.length > 0) {
      if (!model?.parentId) throw new Error("parentId не может быть null");
      if (!branch) throw new Error("передан не правельный parentId");
    }

    try {
      const { data } = await axios.post(`${this.baseUrl}/`, model);
      this.items?.length > 0
        ? branch.children.push(data)
        : (this.items = [data]);
    } catch (error) {
      throw error;
    }
  }

  async updateFolders(
    checkedItems: any,
    componentId: any = requiredArgument(),
  ) {
    try {
      const url = `${this.baseUrl}/components/${componentId}/linkedFolders`;
      await axios.post(url, checkedItems);
    } catch (error) {
      throw error;
    }
  }

  async update(model: any, update?: any) {
    if (!model) throw new Error("Нельзя передать null");
    const branch = findElementInTree(model.id, this.items);
    if (!branch) throw new Error("Элемент не найден");

    const updateCallBack = update
      ? update
      : (defined: any) => {
          defined.caption = model.caption;
          defined.name = model.name;
          defined.description = model.description;
        };

    try {
      const { data } = await axios.put(`${this.baseUrl}/${model.id}`, model);

      updateElementInTree(data, this.items, updateCallBack);
    } catch (error) {
      throw error;
    }
  }
}
