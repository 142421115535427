
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import _ from "lodash";
import { remove } from "@/cms-services/helpers";
import { Filter } from "@/cms-services/filter";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  searchTimer: any = null;
  dialog: boolean = false;
  searchText: string = "";
  filterText: string = "";
  loaded: boolean = false;
  itemsCopy: any = [];
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  reviewDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "review",
  });

  linkedReviewDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "review",
  });
  componentReviewDataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 1000,
      filter: JSON.stringify([new Filter("componentId", this.dataSource.id)]),
    },
    className: "componentreview",
  });

  $message: any;

  updateReviews() {
    let sourceItems = Array.from([...this.reviewDataSource.items]);

    for (const review of sourceItems) {
      const exists = this.componentReviewDataSource.items.some(
        (c: any) => c.reviewId == review.id,
      );
      if (exists) {
        remove(this.reviewDataSource.items, (c: any) => c.id == review.id);
      }
    }
    this.linkedReviewDataSource.items =
      this.componentReviewDataSource.items.map((cf: any) =>
        this.itemsCopy.find((f: any) => f.id == cf.reviewId),
      );
  }

  filterLinkedreview() {
    this.updateReviews();
    if (!this.filterText) return;
    const re = new RegExp(this.filterText, "gi");
    this.linkedReviewDataSource.items =
      this.linkedReviewDataSource.items.filter((c: any) => c.caption.match(re));
  }

  async created() {
    await Promise.all([
      this.reviewDataSource.get(),
      this.componentReviewDataSource.get(),
    ]);

    this.itemsCopy = _.cloneDeep(this.reviewDataSource.items);
    this.linkedReviewDataSource.metadata = this.reviewDataSource.metadata;
    this.linkedReviewDataSource.total =
      this.componentReviewDataSource.items?.length;

    this.updateReviews();
    this.loaded = true;
  }

  async add(item: any) {
    await this.componentReviewDataSource.add({
      componentId: this.dataSource.id,
      reviewId: item.id,
    });
    this.updateReviews();
    this.linkedReviewDataSource.total =
      this.linkedReviewDataSource.items.length;
    this.$message("Успешно добавлено");
  }

  async remove(item: any) {
    const cf = this.componentReviewDataSource.items?.find(
      (c: any) => c.reviewId == item.id,
    );
    await this.componentReviewDataSource.remove(cf.id);
    remove(this.linkedReviewDataSource.items, (c: any) => c.id === item.id);
    this.reviewDataSource.items.push(item);
    this.linkedReviewDataSource.total =
      this.linkedReviewDataSource.items.length;

    this.$message("Успешно удалено");
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.reviewDataSource.config.filter = JSON.stringify([
        new Filter("searchText", value),
      ]);
      await this.reviewDataSource.get();
      this.updateReviews();
    }, 1000);
  }
}
