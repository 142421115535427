export const initCompanyPartnerRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/companypartners",
          name: "companypartner",
          component: () => import("@/pages/manage/companypartners/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/companypartners/:id",
          name: "companypartner-id",
          component: () => import("@/pages/manage/companypartners/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        }
      ];
