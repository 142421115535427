
import Vue from "vue";
import AppForm from "@/bootstrap/components/app-form/app-form.vue";
export default Vue.extend({
  name: "content-form-app",
  components: { AppForm },
  props: {
    form: Object,
  },
  data: () => ({
    sellectedSection: null as any,
    loaded: false,
  }),
  created() {
    this.sellectedSection = this.form?.sections?.[0];
    this.loaded = !!this.sellectedSection;
  },
  watch: {
    "form.sections": {
      deep: true,
      handler(newValue) {
        this.sellectedSection = newValue?.[0];
      },
    },
  },
  computed: {
    isValidForm(): boolean {
      const { model, sections, fields } = this.form;
      return (
        typeof model === "object" &&
        Array.isArray(sections) &&
        Array.isArray(fields)
      );
    },
    filteredFields(): any {
      const sort = (p: any, n: any) => p.priority - n.priority;
      const filter = (f) => f.sectionId === this.sellectedSection.id;
      const fields = this.form?.fields;
      if (!(fields?.length > 0)) return [];
      return !!this.sellectedSection
        ? fields.filter(filter).sort(sort)
        : fields.sort(sort);
    },
  },
  methods: {
    selectSection(section: any) {
      if (!section) throw new Error("Нельзя передать null");
      this.sellectedSection = section;
    },
    async buttonHandler(button) {
      const _valid = await (this as any).$refs.form.validate();

      if (!_valid) {
        (this as any).$alert("Пожалуйста заполните форму правельно");
        return;
      }
      switch (button.action) {
        case "next": {
          const _index = this.form?.sections.findIndex(
            (f) => f.id === this.sellectedSection.id,
          );
          this.sellectedSection = this.form?.sections[_index + 1];
          return;
        }

        case "prev": {
          const _index = this.form?.sections.findIndex(
            (f) => f.id === this.sellectedSection.id,
          );
          this.sellectedSection = this.form?.sections[_index - 1];
          return;
        }

        case "submit":
          console.log({ ...this.form.model });

          return;
      }
    },
  },
});
