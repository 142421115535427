import { instaceStates } from "./consts";
import { Filter } from "./filter";
import { requiredArgument } from "./validators";

export const getGUID = () => {
  const _p8 = (s?: boolean) => {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  };
  return (_p8() + _p8(true) + _p8(true) + _p8()).toUpperCase();
};

export const toQueryString = (object: any) =>
  object ? "?" + new URLSearchParams(object).toString() : "";

export const remove = (source: any[], cb: any) => {
  const exists = source.some(cb);
  if (exists) {
    const index = source.findIndex(cb);
    source.splice(index, 1);
  }
};

export const getValue = (object: any, fieldPath: string) => {
  const regex = new RegExp(/[\.]/gm);
  if (!regex.test(fieldPath)) {
    return object[fieldPath];
  }
  let schema = object;

  const pList = fieldPath.split(".");
  const len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  return schema[pList[len - 1]];
};

export const getFullName = (
  person: any,
  notDefinedCaption = "Не указано",
): string => {
  if (!person) return "";
  const firstName = person.firstName ?? "";
  const lastName = person.lastName ?? "";
  const patronymic = person.patronymic ?? "";
  return /^\s+$/.test(`${lastName} ${firstName} ${patronymic}`)
    ? `${lastName} ${firstName} ${patronymic}`
    : person.caption ?? notDefinedCaption;
};

export const getImageUrl = (o: any, w: number, h: number): string | null =>
  o?.defaultImageId
    ? `/images/ci${o?.defaultImageId}v${w ?? 150}x${h ?? 150}s0.jpg`
    : null;

export const getInstaceStateName = (instaceStateId: number): string =>
  instaceStates[instaceStateId] ?? "[не определен]";

export const findElementInTree = (
  key: any = requiredArgument("значения для поиска не передано"),
  tree: any = requiredArgument("Дерево для поиска не передано"),
  keyName: string = "id",
): any => {
  let _defined: any = null;

  (function find(key: number, tree: any) {
    for (const branch of tree) {
      if (key === branch[keyName]) {
        _defined = branch;
      }

      if (branch?.children?.length > 0) {
        find(key, branch.children);
      }
    }
  })(key, tree);

  return _defined;
};

export const findParentInTree = (
  key: any = requiredArgument("значения для поиска не передано"),
  tree: any = requiredArgument("Дерево для поиска не передано"),
  keyName: string = "id",
): any => {
  let _defined: any = null;

  (function find(key: number, parent: any) {
    for (const branch of parent.children) {
      if (key === branch[keyName]) {
        _defined = parent;
      }

      if (branch?.children?.length > 0) {
        find(key, branch);
      }
    }
  })(key, {children:tree});

  return _defined;
};

export class TransformInfo{
  public from:string;
  public to:string;

  constructor(from:string, to:string) {
    this.from=from;
    this.to=to;
  }
}


export const deepTransformTree = (src:any, info:TransformInfo[], childrenInfo:TransformInfo) => {
  const treeRes:any[] = [];
  for (const srcElement of src) {
    const itemRes = transformObject(srcElement, info);
    if(srcElement[childrenInfo.from].length){
      itemRes[childrenInfo.to] = deepTransformTree(srcElement[childrenInfo.from], info, childrenInfo);
    }
    treeRes.push(itemRes);
  }
  return treeRes;
}

export const getBase64FromFile = (file: File): Promise<string | null> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => {
    const base64 = reader.result?.toString().split(',')[1] || null;
    resolve(base64);
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsDataURL(file);
});



export const transformObject = (src:any, info:TransformInfo[] ) => {
  const res:Record<string, any> = {};
  for (const infoItem of info) {
    res[infoItem.to] = src[infoItem.from];
  }
  return res;
}


export const removeElementInTree = (
  key: any = requiredArgument("значения для поиска не передано"),
  tree: any = requiredArgument("Дерево для поиска не передано"),
  keyName: string = "id",
): any => {
  (function find(key: number, tree: any) {
    for (const [index, branch] of tree.entries()) {
      if (key === branch[keyName]) {
        tree.splice(index, 1);
      }
      if (branch?.children?.length > 0) {
        find(key, branch.children);
      }
    }
  })(key, tree);
};

export const updateElementInTree = (
  data: any = requiredArgument("Данные для обновления не передано"),
  tree: any = requiredArgument("Дерево для поиска не передано"),
  update: Function = requiredArgument("CallBack для update а не передано"),
  keyName: string = "id",
): any => {
  for (const branch of tree) {
    if (data[keyName] === branch[keyName]) return update(branch);
    if (!branch?.children) continue;
    updateElementInTree(data, branch.children, update);
  }
};

export const getParentBrachesOfTreeByKeyList = (
  keyList: any = requiredArgument("Элементы для поиска не переданы"),
  tree: any = requiredArgument("Дерево для поиска не передано"),
  keyName: string = "id",
  childrenKeyName: string = "children",
  parentKeyName: string = "parentId",
): any => {
  const branchList: any = [];
  for (const key of keyList) {
    let branch: any = {
      ...findElementInTree(key, tree),
      [childrenKeyName]: [],
    };
    const isExist = branchList.find((b: any) => b[keyName] === branch[keyName]);
    if (isExist) continue;
    branchList.push(branch);
    while (branch[parentKeyName] != null) {
      branch = {
        ...findElementInTree(branch[parentKeyName], tree),
        [childrenKeyName]: [],
      };
      const isExist = branchList.find(
        (b: any) => b[keyName] === branch[keyName],
      );
      if (isExist) continue;
      branchList.push(branch);
    }
  }
  return branchList;
};

export const getLastLevelBranches = (
  tree: any = requiredArgument("Дерево  не передано"),
  childrenKeyName: string = "children",
) => {
  const lastLevelBranches: any = [];

  (function recursiveFind(tree) {
    for (const branch of tree) {
      !(branch[childrenKeyName]?.length > 0)
        ? lastLevelBranches.push(branch)
        : recursiveFind(branch[childrenKeyName]);
    }
  })(tree);

  return lastLevelBranches;
};

export const getFileBase64 = (fileData: any) =>
  fileData?.fileContents
    ? `data:${fileData.contentType};base64,${fileData?.fileContents}`
    : null;

export const toFilterString = (obj: any) =>
  JSON.stringify(
    Object.entries(obj).map(([k, v]) => new Filter(k, v as string)),
  );
export const generateBreadCrumbsFromTree = (
  lastBranch: any = requiredArgument(),
  tree: any = requiredArgument("tree не может быть null"),
  mapPrediacate?: any,
  parentKeyName: any = "parentId",
) => {
  let branch = { ...lastBranch };
  const breadcrumbs: any = [];
  breadcrumbs.push(branch);
  while (branch[parentKeyName]) {
    const prevBranch = findElementInTree(branch[parentKeyName], tree);
    if (!prevBranch) break;
    breadcrumbs.push(prevBranch);
    branch = prevBranch;
  }
  return breadcrumbs.reverse().map((x: any) => mapPrediacate(x));
};

export const getFilePreview = (fileName: string) => {
  switch (fileName.substring(fileName.lastIndexOf(".") + 1)) {
    case "jpg":
    case "JPG":
    case "JPEG":
    case "jpeg":
      return "image-sprite";
    case "pdf":
      return "pdf";
    case "docx":
    case "doc":
      return "docx";
    case "xlsx":
      return "xlsx";
    case "txt":
      return "txt";
    case "pptx":
    case "ppt":
      return "pptx";
    case "mp3":
      return "mp3";
    case "zip":
    case "rar":
      return "zip";
    default:
      return "file";
  }
};

export const generatePassword = () => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const passwordLength = 8;
  let password = "";
  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
};

export const translit = (s: string) => {
  const L = {
    А: "A",
    а: "a",
    Б: "B",
    б: "b",
    В: "V",
    в: "v",
    Г: "G",
    г: "g",
    Д: "D",
    д: "d",
    Е: "E",
    е: "e",
    Ё: "Yo",
    ё: "yo",
    Ж: "Zh",
    ж: "zh",
    З: "Z",
    з: "z",
    И: "I",
    и: "i",
    Й: "Y",
    й: "y",
    К: "K",
    к: "k",
    Л: "L",
    л: "l",
    М: "M",
    м: "m",
    Н: "N",
    н: "n",
    О: "O",
    о: "o",
    П: "P",
    п: "p",
    Р: "R",
    р: "r",
    С: "S",
    с: "s",
    Т: "T",
    т: "t",
    У: "U",
    у: "u",
    Ф: "F",
    ф: "f",
    Х: "Kh",
    х: "kh",
    Ц: "Ts",
    ц: "ts",
    Ч: "Ch",
    ч: "ch",
    Ш: "Sh",
    ш: "sh",
    Щ: "Sch",
    щ: "sch",
    Ъ: "",
    ъ: "",
    Ы: "Y",
    ы: "y",
    Ь: "",
    ь: "",
    Э: "E",
    э: "e",
    Ю: "Yu",
    ю: "yu",
    Я: "Ya",
    я: "ya",
    " ": "-",
    "«": "",
    "»": "",
    "%": "",
    "&": "",
    "?": "",
    "#": "",
    $: "",
    "^": "",
    "*": "",
    "№": "",
    ".": "",
    ",": "",
    "–": "",
  };
  let r;
  let k;
  for (k in L) {
    if (k) {
      r += k;
    }
  }

  r = new RegExp(`[${r}]`, "g");

  k = (a) => (a in L ? L[a] : "");

  return s
    .replace(/#|!|"|:|(|)|{|}|%|&|\?|\$|\^/g, "")
    .trim()
    .replace(r, k)
    .replace("--", "-")
    .toLowerCase();
};
