
import { Vue, Component } from "vue-property-decorator";
import Ace from "@/components/helpers/ace.vue";
//import UiHtmlEditor from "@/components/helpers/ui-html-editor.vue";
import Editor from "./editor";
import { quillEditor } from "vue-quill-editor";
import beautify from "js-beautify";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

@Component({
  mixins: [Editor],
  components: { Ace, quillEditor /*UiHtmlEditor*/ },
})
export default class HmtlInlineEditor extends Vue {
  dialog: boolean = false;
  mode: any = null;
  oldMode: any = null;
  modeList: any = ["Редактор", "HTML", "Просмотр"];
  save: any;
  loading: boolean = false;
  cancelChanges: any;
  htmlValue: string = "";
  value: any;

  async saveChanges() {
    this.loading = true;
    if (this.mode == 1) {
      this.value = this.htmlValue;
    }
    await this.save();
    this.loading = false;
    this.dialog = false;
  }

  discartChanges() {
    this.cancelChanges();
    this.dialog = false;
  }

  // updateHtmlValue({ quill, html, text }) {
  //   //const valid = this.isValidHTML(event);
  //   // console.log(this.htmlValue);
  //   //  if (!valid) return;
  //   this.value = html;
  //   //console.log(this.value);
  // }

  // updateHtmlValue({ quill, html, text }) {
  //   this.htmlValue = this.format(html);
  //   this.value = this.htmlValue;
  // }

  updateMode(mode) {
    if (this.oldMode == 1 && this.mode == 0) {
      this.value = this.htmlValue;
    }
    if (this.oldMode == 0 && this.mode == 1) {
      this.htmlValue = this.format(this.value);
    }
    this.oldMode = mode;
  }

  format(html) {
    return beautify.html(html, { wrap_attributes: "force-aligned" });
  }
  // formatOld(html) {
  //   html = html ?? "";
  //   var tab = "\t";
  //   var result = "";
  //   var indent = "";

  //   html.split(/>\s*</).forEach(function (element) {
  //     if (element.match(/^\/\w/)) {
  //       indent = indent.substring(tab.length);
  //     }

  //     result += indent + "<" + element + ">\r\n";

  //     if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
  //       indent += tab;
  //     }
  //   });

  //   return result.substring(1, result.length - 3);
  // }

  // isValidHTML(html) {
  //   const parser = new DOMParser();
  //   const doc: any = parser.parseFromString(html, "text/xml");
  //   if (doc.documentElement.querySelector("parsererror")) {
  //     return doc.documentElement.querySelector("parsererror")?.innerText;
  //   } else {
  //     return true;
  //   }
  // }
}
