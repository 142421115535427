import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";

export const tokenName = process.env.TOKEN_NAME || "jwt-token";

export const isJwtValid = (jwt: string | undefined | null): boolean => {
  if (!jwt || jwt === "undefined") return false;
  const jwtData: any = jwtDecode(jwt) || {};
  const expires = jwtData.exp || 0;
  return new Date().getTime() / 1000 < expires;
};

export const checkToken = () => {
  const tokenFromAxios = axios.defaults.headers.common["Authorization"];

  if (!tokenFromAxios) {
    const tokenFromStorage = Cookies.get(tokenName);
    if (!isJwtValid(tokenFromStorage)) return;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokenFromStorage}`;
    return;
  }

  if (isJwtValid(tokenFromAxios.replace("Bearer ", ""))) return;
  Cookies.remove(tokenName);
};
