
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";

@Component
export default class EditableElement extends Vue {
  @Prop() value!: string;

  @Prop({ default: true }) canEdit!: boolean;

  @Prop({ default: "div" }) tag!: string;

  @Ref("editable") editable!: HTMLElement;

  canUpdate: boolean = true;
  tempValue: string = "";

  @Watch("value")
  onValueChanged(val: string) {
    if (this.canUpdate) {
      this.editable.innerText = val;
    }
  }

  mounted() {
    this.editable.innerText = this.value;
    this.tempValue = this.value;

    const element:HTMLElement = this.$refs!.editable as HTMLElement;

    element.addEventListener('paste', function(e:any){
      e.preventDefault()
      e.stopPropagation()
      let paste = (e.clipboardData || (window as any).clipboardData).getData('text/plain')
      paste = paste.replace(/\x0D/gi, "\n")
      e.target.textContent = paste
    })
  }

  onInput(e) {
    this.$emit("input", e.target.innerText);
  }

  onFocus() {
    this.canUpdate = false;
  }

  onBlur(e) {
    this.canUpdate = true;
    if (this.tempValue === e.target.innerText) return;
    this.$emit("change", e.target.innerText);
  }
}
