import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component, Prop } from "vue-property-decorator";
import ObjectExpansionEditor from "@/components/helpers/object-expansion-editor.vue";
import draggable from "vuedraggable";
import { mdiClose, mdiCog } from "@mdi/js";
import appConfigModule from "@/store/plugins/appConfig";
@Component({
  components: {
    ObjectExpansionEditor,
    draggable,
  },
})
export default class DataItem extends Vue {
  @Prop() viewName!:string
  @Prop() dataSource!: ObjectDataSource;
  @Prop() breadcrumbs!: any;
  @Prop({ default: "Редактировать объект" }) title!: string;
  @Prop({}) customMetaData!: any;
  @Prop({ default: false }) preventGet!: boolean;
  drawer: boolean = false;
  loaded: boolean = false;
  show: boolean = false;
  view:any = null;

  icons: any = {
    setting: mdiCog,
    close: mdiClose,
  };
  $message: any;



  get pageTitle() {
    return this.title ?? this.dataSource.model?.caption;
  }

  async created() {
    if(!this.preventGet){
      await this.dataSource.get();
    }
    this.view = await appConfigModule.getView(this.viewName) ?? {
      name:"default",
      view:"default"
    };


    !this.customMetaData
      ? await this.dataSource.getMetaData()
      : (this.dataSource.metadata = this.customMetaData);
    this.loaded = true;
    this.$emit("data-item-mounted");
  }
}
