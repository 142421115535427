
import { mdiClose } from "@mdi/js";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import AppForm from "@/components/app-form/app-form.vue";
@Component({
  components: { AppForm },
})
export default class DataCreator extends Vue {
  @Ref("form") form!: AppForm;
  @Prop({ default: 600 }) maxWidth!: number;

  @Prop() fields!: any;
  @Prop() customValidators!: any;
  @Prop({ default: () => ({}) }) actions!: any;
  @Prop() title!: string;
  @Prop() subtitle!: string;

  model: any = {};
  visible: boolean = false;
  resolve!: Function;

  get slots() {
    return this.fields.filter((f) => f.editor == "slot");
  }

  icons: any = {
    close: mdiClose,
  };
  $message: any;

  async mount(data: any) {
    this.model = JSON.parse(JSON.stringify(data));
    this.visible = true;
    await this.$nextTick();
    this.form.reset();
  }

  public async create(data: any, clearObject = true) {
    await this.mount(data);
    if (clearObject) {
      for (const key in this.model) {
        this.model[key] = null;
      }
    }

    return new Promise((r: Function) => (this.resolve = r));
  }

  public async update(data: any) {
    await this.mount(data);
    return new Promise((r: Function) => (this.resolve = r));
  }

  close(model:any) {
    this.resolve(model);
    this.visible = false;
    this.model = null;
  }

  async submitForm() {
    const valid = await this.form.validate();
    if (!valid) {
      this.$message("Пожалуйста заполните форму правильно", "error");
      return;
    }
    this.close(this.model);
  }
}
