
import Vue from "vue";
import { BSidebar, BCollapse } from "bootstrap-vue";
import AppForm from "@/bootstrap/components/app-form/app-form.vue";
import { contentBlockModule } from "@/bootstrap/store/contentBlock";
import contentBlockGroups from "../../../content-blocks.json"
export default Vue.extend({
  name: "content-block-configurator",
  components: { BSidebar, BCollapse, AppForm },
  data: () => ({
    module: contentBlockModule,
    block: null as any,
    blockMetaInfo: null as any,
  }),
  computed: {
    blockGroups() {
      return (this as any).blockMetaInfo?.groups ?? [];
    },
    blockFields() {
      return (this as any).blockMetaInfo?.fields ?? [];
    },
  },
  created() {
    if (!this.block)
      this.$store.commit(contentBlockModule.mutations.closeBlockConfigurator);
  },
  methods: {
    sidebarEvent(event) {
      if (!event) {
        this.$store.commit(contentBlockModule.mutations.closeBlockConfigurator);
        return;
      }
      this.block = this.$store.getters[contentBlockModule.getters.block];
      this.blockMetaInfo = (this as any).getBlockMetaInfo(this.block);
      console.log(this.blockMetaInfo)
    },
    async undateConfig() {
      if (!this.block) return;
      const { state, commit } = this.$store;
      const dataSource = state[contentBlockModule.name].dataSource;
      if (!dataSource) throw new Error("dataSource не передан");
      const id = this?.block?.id;

      const updatedData = await dataSource.updateField(id, [
        {
          fieldName: "config",
          fieldValue: this.block.config,
        },
      ]);
      commit(contentBlockModule.mutations.updateBlock, updatedData);
    },
    getBlockMetaInfo(block) {
      if (!block) return null;
      const group = contentBlockGroups.find((x: any) => x.group === block.group);
      console.log(group, contentBlockGroups)
      if (!group) return null;

      const metaInfo = Array.from(group.blocks as any[]).find((b: any) => b.name === block.name)
        ?.metaInfo?.config;

      return metaInfo ?? null;
    },
  },
});
