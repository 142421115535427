import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import appConfigModule from "../plugins/appConfig";

@Module({ store, namespaced: true, name: "userModule", dynamic: true })
class UserModule extends VuexModule {
  public profile: any = null;
  public roles: Array<string> = [];

  public get isAdmin() {
    return this.roles.includes(appConfigModule.config.roles.administrators);
  }

  @Mutation
  public setProfile(profile: any): void {
    this.profile = profile;
  }

  @Mutation
  public setRoles(roles: any): void {
    this.roles = roles;
  }

  @Action({ rawError: true })
  public async getUser(): Promise<any> {
    try {
      appConfigModule.setLoading();
      const { data } = await axios.get("/user/profile/");
      this.setProfile(data.profile);
      this.setRoles(data.roles);
      appConfigModule.updateNavigations(data.roles);
    } catch (error) {
      appConfigModule.setError({
        status: 400,
        message: "Ошибка при получении профиля пользователя",
      });
    } finally {
      appConfigModule.clearLoading();
    }
  }
}
const userModule = getModule(UserModule);

export default userModule;
