import Vue from "vue";

const container = Vue.extend({
  props: {
    fields: {
      type: Array,
    },
    model: {
      type: Object,
    },
    actions: {
      type: Object,
    },
    customValidators: {
      type: Object,
    },
    editMode: {
      type: Boolean,
    },
    nested: {
      type: Array,
    },
    attrs: {
      type: Object,
    },
  },
  methods: {
    getProviderName: (field: any) => field?.provider?.name ?? "default",
    getGrid: (grid: any) => grid ?? { class: "col-12" },
  },
});

export default container;
