
import Vue from "vue";
import Ace from "@/bootstrap/components/editor/ace.vue";
import { BTabs, BTab } from "bootstrap-vue";
export default Vue.extend({
  components: { BTabs, BTab, Ace },
  data: () => ({
    visible: false,
    json: "",
    resolve: null as any,
  }),
  methods: {
    configurate(field) {
      this.json = JSON.stringify(field, null, 2);
      this.visible = true;
      return new Promise<void>((resolve) => (this.resolve = resolve));
    },

    close(model) {
      this.resolve(model);
      this.visible = false;
    },
    submit() {
      try {
        this.close(JSON.parse(this.json));
      } catch (error) {
        (this as any).$alert("Пожалуйста заполните корректно");
      }
    },
  },
});
