export const initFeatureRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/features",
          name: "feature",
          component: () => import("@/pages/manage/features/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/features/:id",
          name: "feature-id",
          component: () => import("@/pages/manage/features/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
