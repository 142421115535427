
import { ContentBlock } from "@/bootstrap/components/content/block";
export default ContentBlock.extend({
  name: "content-block-text-01",
  computed: {
    breakpoint() {
      return (this as any).block?.config?.breakpoint;
    },
  },
});
