
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class BoolInlineEditor extends Vue {
  field: any;
  get mode() {
    const mode = this.field?.config?.mode;
    return mode ?? "checkbox";
  }

  get isCheckbox() {
    return this.mode == "checkbox";
  }

  get isSwitch() {
    return this.mode == "swith";
  }
}
