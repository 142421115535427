
import { ContentBlockFileDataSource } from "@/data/List/ContentBlockFileDataSource";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import draggable from "vuedraggable";
import {
  mdiPlus,
  mdiArrowAll,
  mdiCog,
  mdiTrashCan,
  mdiPen,
  mdiUpload,
  mdiStar,
} from "@mdi/js";
@Component({
  components: { draggable, ImageEditor },
})
export default class ContentBlockFileList extends Vue {
  @Prop() dataSource!: ContentBlockFileDataSource;
  @Prop({ default: true }) editMode!: boolean;
  @Ref("imageEditor") imageEditor!: ImageEditor;
  $message: any;
  $confirm: any;

  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
  };
  resolve: Function | null = null;

  async editImage(image: any): Promise<any> {
    const src = `/api/v1/manage/ContentBlockFile/${image.id}/file`;
    const base64 = await this.imageEditor.edit(src);
    if (!base64) return;
    image.base64 = base64?.toString().split(",")[1];
    try {
      await this.dataSource.update(image);
      this.$message("Файл успешно изменён");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async deleteFile(image: any) {
    const confirm: any = await this.$confirm(
      "Вы действительно хотите удалить данный файл?",
    );
    if (!confirm) return;
    try {
      await this.dataSource.remove(image.id);
      this.$message("Файл успешно удален");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  public getImage() {
    return new Promise<string>((r) => (this.resolve = r));
  }

  selectImage(image) {
    if (!this.resolve) return;
    this.resolve(`/api/v1/manage/ContentBlockFile/${image.id}/file`);
  }
}
