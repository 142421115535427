
import { Vue, Component } from "vue-property-decorator";
import { BIconPlus } from "bootstrap-vue";
import { ContentBlock } from "../index";
import { ListDataSource } from "@/data/List/ListDataSource";
import { mapGetters } from "vuex";
import { contentBlockModule } from "@/bootstrap/store/contentBlock";
import ContentBlockController from "@/bootstrap/components/content/block/controller.vue";
import { getContentBlockDataSource } from "@/bootstrap/helper";

@Component({
  name: "content-block-grid-01",
  components: { BIconPlus, ContentBlockController },
  mixins: [ContentBlock],
  computed: {
    ...mapGetters({
      contentId: contentBlockModule.getters.contentId,
    }),
  },
})
export default class ContentBlockGrid01 extends Vue {
  colsDataSource: ListDataSource | null = null;
  loaded: boolean = false;
  preview: any;
  contentId: any;
  block: any;

  async created() {
    if (this.preview) return;
    this.colsDataSource = getContentBlockDataSource(
      this.contentId,
      this.block.id,
    );
    await this.colsDataSource.get();
    this.loaded = true;
  }
  getGrid = (item) => item?.config?.grid ?? "col";
  configurate() {
    console.log("configurate");
  }
  async addCol() {
    if (!this.colsDataSource) return;
    await this.colsDataSource.add({
      group: "grid",
      name: "col",
      parentId: this.block.id,
      contentId: this.contentId,
    });
  }
  getDataSource(item: any) {
    return getContentBlockDataSource(this.contentId, item.id);
  }
}
