import Home from "@/pages/index.vue";

export const initFileSystemRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
      {
        path: "/manage/filesystem",
        name: "fileSystem",
        component: () => import("@/pages/manage/filesystem/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ];
