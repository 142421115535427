
import { Vue, Component } from 'vue-property-decorator';
import messageModule from '@/store/plugins/message';
import { mdiClose } from '@mdi/js';

@Component
export default class Message extends Vue {
  icons: any = {
    close: mdiClose,
  };

  message = messageModule;
}
