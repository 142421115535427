
import { Filter, FilterUtils } from "@/cms-services/filter";
import { requiredArgument } from "@/cms-services/validators";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component } from "vue-property-decorator";
@Component
export default class FolderRestrictor extends Vue {
  visible: boolean = false;
  folder: any = null;
  resolve!: Function;
  loaded: boolean = true;
  filterUtils: FilterUtils = new FilterUtils([]);
  roleDataSource: ListDataSource = new ListDataSource({
    config: {
      pageSize: 1000,
    },
    className: "roles",
  });

  folderAccessDataSource: ListDataSource = new ListDataSource({
    config: { pageSize: 1000 },
    className: "FolderAccess",
  });

  getInputValue(role: any) {
    const cb = (fa: any) => fa.roleId == role.id;
    const folderAccess = this.folderAccessDataSource.items.find(cb);
    return !!folderAccess;
  }

  async created() {
    await this.roleDataSource.get();
    this.loaded = true;
  }

  async addOrDeleteFolderAccess(role: any, $event: any) {
    const cb = (fa: any) => fa.roleId == role.id;
    const folderAccess = this.folderAccessDataSource.items.find(cb);
    try {
      if ($event && !folderAccess) {
        await this.folderAccessDataSource.add({
          roleId: role.id,
          folderId: this.folder.id,
        });
        return this.$message("Успешно добавлено");
      }
      if (!!folderAccess) {
        await this.folderAccessDataSource.remove(folderAccess.id);
        return this.$message("Успешно удалено");
      }
      this.$message("Ошибка", "error");
    } catch (error) {
      this.$message("Ошибка", "error");
    }
  }
  $message: any;

  async filterFolderAccess(folderId: number = requiredArgument()) {
    this.filterUtils.clear();
    this.filterUtils.add(new Filter("folderId", folderId));
    this.folderAccessDataSource.config.filter = this.filterUtils.filterString;
    await this.folderAccessDataSource.get();
  }

  public async restrict(folder: any = requiredArgument()) {
    this.folder = folder;
    await this.filterFolderAccess(folder.id);
    this.visible = true;
    return new Promise((r) => (this.resolve = r));
  }

  close() {
    this.resolve();
    this.visible = false;
  }
}
