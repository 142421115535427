
import { mdiClose } from "@mdi/js";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import AppForm from "@/components/app-form/app-form.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
import { required } from "@/cms-services/consts";
import { Filter } from "@/cms-services/filter";
@Component({
  components: { AppForm },
})
export default class DataCreator extends Vue {
  @Ref("form") form!: AppForm;
  @Prop({ default: 600 }) maxWidth!: number;

  @Prop() fields!: any;
  @Prop({ default: () => ({}) }) actions!: any;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  loaded: boolean = false;
  model: any = {};
  visible: boolean = false;
  resolve!: Function;
  icons: any = {
    close: mdiClose,
  };
  $message: any;

  dataSource: ListDataSource = new ListDataSource({
    className: "FolderScenario",
    config: {},
  });

  mailScenarioDataSource: ListDataSource = new ListDataSource({
    className: "MailScenario",
    config: {
      pageSize: 1000,
    },
  });

  createItemModel: any = {
    folderId: null,
    mailScenarioId: null,
  };

  createItemFields: any = [
    {
      editor: "auto-complete-select",
      attrs: {
        outlined: true,
        label: "Выберите уведомления",
      },
      config: {
        getItems: "getMailScenarios",
        labelKeyName: "caption",
        valueKeyName: "id",
      },
      name: "mailScenarioId",
      validations: [required()],
    },
  ];

  createItemActions: any = {
    getMailScenarios: async () => {
      await this.mailScenarioDataSource.get();
      return this.mailScenarioDataSource.items;
    },
  };

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  public async edit(folderId: number) {
    this.createItemModel.folderId = folderId;
    this.dataSource.config.filter = JSON.stringify([
      new Filter("folderId", folderId),
    ]);
    await this.dataSource.get();
    this.visible = true;
    return new Promise((r: Function) => (this.resolve = r));
  }

  close(model) {
    this.resolve(model);
    this.visible = false;
    this.model.mailScenarioId = null;
  }
}
