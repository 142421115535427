
import { ContentBlock } from "@/bootstrap/components/content/block";
export default ContentBlock.extend({
  name: "content-block-img-01",
  computed: {
    breakpoint() {
      return (this as any).block?.config?.breakpoint;
    },
    height() {
      return (this as any).block?.config?.height;
    },
    sizes(){
      const width =  (this as any).block?.config?.width;
      const height =  (this as any).block?.config?.height;
      let size = "";
      if(width) size += `width:${width};`
      if(height) size += `height:${height};`
      return size;
    }
  },
  methods: {
    async selectImage(selectFile, block, name) {
      const file = await selectFile();
      block.model[name] = `/api/v1/objectimage/p${file.id}.jpg`;
      await this.updateModel();
      (this as any).$message("Изображение успещно обновлено");
    },
  },
});
