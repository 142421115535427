
import editor from "./editor";
import { Vue, Component, Prop } from "vue-property-decorator";
import FileMixin from "@/mixins/file-types/index";
import axios from "axios";
import VueGallerySlideshow from "vue-gallery-slideshow";
@Component({
  components: { VueGallerySlideshow },
  mixins: [FileMixin, editor],
})
export default class FileViewer extends Vue {
  index: any = null;
  dataSource: any;
  dialog: boolean = false;
  value: any;
  save: any;
  async showOrDownload(file: any) {
    const isImage = this.isImage(file.fileName);
    if (!isImage) {
      const { data } = await axios.get(
        `/attachedfile/${file.id}/file/?download=true`,
        {
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }
    this.index = this.images?.findIndex?.((f: any) => f.id == file.id);
  }

  async select(file) {
    this.value = file.id;
    await this.save();
  }

  get files() {
    return this.dataSource?.model?.linkedFiles;
  }

  get images() {
    return this.files?.filter?.((f: any) => this.isImage(f.fileName));
  }

  get imageUrls() {
    return this.images?.map?.((f: any) => `/api/v1/attachedfile/${f.id}/file`);
  }

  getFileComponent = (file: any) => {
    switch (file.fileName.substring(file.fileName.lastIndexOf(".") + 1)) {
      case "jpg":
      case "JPG":
      case "JPEG":
      case "jpeg":
      case "png":
      case "PNG":
        return "img";
      case "pdf":
        return "pdf";
      case "docx":
      case "doc":
        return "docx";
      case "xlsx":
        return "xlsx";
      case "txt":
        return "txt";
      case "pptx":
      case "ppt":
        return "pptx";
      case "mp3":
        return "mp3";
      case "zip":
      case "rar":
        return "zip";
      default:
        return "file";
    }
  };

  isImage = (fileName: string) => {
    const ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    return ["jpg", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(ext);
  };

  getFileSrc = (file: any) => {
    return this.isImage(file.fileName)
      ? `/api/v1/attachedfile/${file.id}/file`
      : null;
  };
}
