
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import editor from "./editor";
import editable from "../../helpers/editable.vue";
@Component({
  components: { editable },
  mixins: [editor],
})
export default class ElemInlineEditor extends Vue {
  @Ref("el") el!: editable;
  @Prop({ default: "div" }) tag!: string;
  setEditMode: any;
}
