import {
  Module,
  VuexModule,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

@Module({ store, namespaced: true, name: 'messageModule', dynamic: true })
class Message extends VuexModule {
  public visible: boolean = false;

  public type: string = 'error';

  public text: string = 'Это тестовое сообшение';

  public icon: string = '';

  public timeout: number = 2000;

  @Mutation
  public showMessage(params: any): void {
    this.text = params.message ?? '';
    this.type = params.type ?? 'success';
    this.icon = params.icon ?? '';
    this.timeout = params.timeout ?? 2000;
    this.visible = true;
  }

  @Mutation
  public close(): void {
    this.visible = false;
  }
}

export default getModule(Message);
