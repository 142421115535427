import { validate } from "@/cms-services/validators";
import { Vue, Prop, Watch, Component } from "vue-property-decorator";

@Component
export default class Editor extends Vue {
  @Prop() field!: any;
  @Prop() model!: any;
  @Prop() actions!: any;
  @Prop() customValidators!: any;

  attrs: any = {};
  validations: any = [];
  watchers: any;
  config: any = {};

  get showErrors() {
    return this.field?.errorMessages?.length > 0;
  }

  get value() {
    const regex = new RegExp(/[\.]/gm);

    if (!regex.test(this.field.name)) {
      return this.model[this.field.name];
    }
    let schema = this.model;

    const pList = this.field.name.split(".");
    const len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    return schema[pList[len - 1]];
  }

  set value(value: any) {
    const regex = new RegExp(/[\.]/gm);
    if (!regex.test(this.field.name)) {
      this.model[this.field.name] = value;
    }
    let schema = this.model;

    const pList = this.field.name.split(".");
    const len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]] = value;
  }

  @Watch("model", { deep: true })
  async onModelChanged() {
    if (!(this.watchers?.length > 0)) return;
    for (const watcher of this.watchers) {
      const { changeModel, changeAttrs, changeValidations } = watcher;
      if (!!changeModel) await this.actions[changeModel](this.model);
      if (!!changeAttrs)
        this.attrs = await this.actions[changeAttrs](this.attrs);
      if (!!changeValidations)
        this.validations = await this.actions[changeValidations]();
    }
  }

  created() {
    if (!this.field) throw new Error("Настройки поля не переданы");
    this.attrs = this.field?.attrs;
    this.validations = this.field?.validations;
    this.watchers = this.field?.watchers;
    this.config = this.field?.config;
    this.onModelChanged();
  }

  emit() {
    this.$emit("change", this.field);
  }

  public reset(): void {
    this.field.errorMessages = [];
    this.field.successMessages = [];
  }

  public async validate(): Promise<boolean> {
    if (!this.field?.validations) return true;

    const _res: any = await validate(
      this.field,
      this.model,
      this.customValidators,
    );

    this.field.errorMessages = _res.errorMessages;
    this.field.successMessages = _res.successMessages;
    return this.field.errorMessages.length === 0;
  }
}
