
import Editor from "./editor";
import { BIconPen } from "bootstrap-vue";
import Ace from "@/bootstrap/components/editor/ace.vue";
import UiHtmlEditor from "@/components/helpers/ui-html-editor.vue";
export default Editor.extend({
  components: { UiHtmlEditor, Ace, BIconPen },
  data: () => ({
    dialog: false,
    mode: null,
    modeList: ["Редактор", "HTML", "Просмотр"],
    loading: false,
    isAce: false,
  }),
  methods: {
    change(type: string, newVal: string) {
      if (this.mode == 1 && type == "ui") return;
      (this as any).value = newVal;
      this.$emit("change");
    },
  },
});
