
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { BSidebar } from "bootstrap-vue";
import ContentBlockText01 from "./text/01.vue";
import ContentBlockText02 from "./text/02.vue";
import ContentBlockImage01 from "./img/01.vue";
import ContentBlockImage02 from "./img/02.vue";
import ContentBlockImage03 from "./img/03.vue";
import ContentBlockDocument01 from "./document/01.vue"

import { contentBlockModule } from "@/bootstrap/store/contentBlock";

export default Vue.extend({
  name: "content-block-pallete",
  components: {
    BSidebar,
    ContentBlockText01,
    ContentBlockText02,
    ContentBlockImage01,
    ContentBlockImage02,
    ContentBlockImage03,
    ContentBlockDocument01
  },
  data: () => ({
    blocksVisible: false,
    currentGroup: {},
    module: contentBlockModule,
  }),
  computed: {
    ...mapGetters({
      contentBlocks: contentBlockModule.getters.blocks,
    }),
  },
  methods: {
    ...mapMutations({
      closeBlockPallete: contentBlockModule.mutations.closeBlockPallete,
    }),
    openBlocksPallete(group: any) {
      this.currentGroup = group;
      this.blocksVisible = true;
    },
    async addBlock(block: any) {

      await this.$store.dispatch(contentBlockModule.actions.addBlock, block);
      (this as any).$alert(`Блок успешно добавлен`);
    },
  },
});
